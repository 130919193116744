import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    H1,
    P,
    ValidationForm,
    Btn,
    FieldSelect,
    Grid,
    BtnText,
} from '@saladbob/sassafras';

const headingFont = {
    phone: 'h3',
    laptop: 'h1',
};

const padding = {
    phone: 'md',
    desktop: 'xxl',
};

const subheadingFont = {
    phone: 'p',
    laptop: 'leader',
};

const columns = {
    phone: '1fr',
    desktop: '1fr 1fr',
};

const Pop = ({
    publicPath,
    imgPath,
    brandPath,
    interSubmit,
    interOptions,
    brandName,
}) => (
    <Box
        minHeight="calc(100vh - 140px)"
        bgColor="neutral50"
    >
        <Grid columns={columns}>
            <Box
                minHeight="calc(100vh - 140px)"
                bgImage={`${publicPath}${imgPath}${brandPath}inter-bg.jpg`}
            />
            <Box height="auto" padding={padding}>
                <Box maxWidth="600px">
                    <H1 font={headingFont} weight="normal" mb="md">We can connect you with a lender</H1>

                    <P font={subheadingFont}>
                        While we are unable to connect you with a Personal Loan lender
                        for your requested loan amount, we work with several alternative lenders that may
                        approve you for a loan between $250 to $2,500
                    </P>
                    <ValidationForm onSubmit={interSubmit}>
                        <FieldSelect
                            name="amt"
                            label={<strong>Select Loan Amount</strong>}
                            font="leader"
                            size="lg"
                            options={interOptions}
                        />
                        <Btn type="submit" size="xl" mt="lg" mb="lg" font="h1" full>
                            REQUEST YOUR LOAN NOW
                        </Btn>
                    </ValidationForm>
                    <ValidationForm onSubmit={interSubmit}>
                        <input type="hidden" name="pass" value="No thanks" />
                        <BtnText
                            type="submit"
                            value="No thanks"
                            mb="lg"
                        >
                            No Thanks
                        </BtnText>
                    </ValidationForm>
                    <P font="control">
                        Consumer Advisory:
                        {' '}
                        {brandName}
                        {' '}
                        is not a Lender and does not make credit decisions or Brokers.
                        {' '}
                        Payday loans are provided made forfor the purpose of assisting you consumers with
                        {' '}
                        short-term financing to resolve immediate money needs and should not be considered
                        {' '}
                        a long-term financial solution. Thus, payday loans are different from personal loans.
                        {' '}
                        Payday loans are relatively expensive when compared with other loan products.
                        {' '}
                        The APR range for our lenders is typically 260.71% to 1825% APR. The APR range
                        {' '}
                        is not your finance charge. You will find out your finance charge later in the
                        {' '}
                        process from your lender. Selecting &ldquo;Continue&rdquo; or clicking the submit button
                        {' '}
                        above SkyLoan.com does not guarantee that you will be matched with an independent
                        {' '}
                        payday lenders or approved for a cash advance or payday loan. Payday Not all
                        {' '}
                        lenders can provide you with up to $1,000 on your first advance. Often, loan
                        {' '}
                        amounts vary and are dependent upon the laws of the State in which you reside
                        {' '}
                        and on independent lenders review. Transfer times for loans may vary between
                        {' '}
                        individual lenders and may depend on your individual financial institution. loan
                        {' '}
                        terms, including loan amounts, interest rates, and transfer times for loans vary
                        {' '}
                        among lenders and may be dependent upon the laws of the state in which you reside.
                    </P>
                </Box>
            </Box>
        </Grid>
    </Box>
);

Pop.propTypes = {
    publicPath: PropTypes.string,
    imgPath: PropTypes.string,
    brandPath: PropTypes.string,
    interSubmit: PropTypes.func,
    brandName: PropTypes.string,
    interOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
};

Pop.defaultProps = {
    publicPath: window.publicPath,
    imgPath: window.imgPath,
    brandPath: window.brandPath,
    interSubmit: window.interSubmit,
    brandName: window.brandName || 'domain.com',
    interOptions: window.interOptions || [
        { value: '600', label: '$501 - $750' },
        { value: '750', label: '$751 - $1000' },
        { value: '1000', label: '$1000+' },
    ],
};

export default Pop;
