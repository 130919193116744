import React from 'react';
import { CheckCircle } from 'react-feather';
import {
    Box, Grid,
} from '@saladbob/sassafras';

const BlurbForm = () => (
    <Box margin={[0, 0, 'xl', 0]}>
        <Box
            rounded="sm"
            padding="md"
            depth="xxl"
            maxWidth="320px"
            margin={[0, 'auto', 'lg', 'auto']}
        >
            <Box
                bgColor="neutral100"
                rounded="xs"
                height="48px"
                margin={[0, 0, 'md', 0]}
            />
            <Grid columnGap="sm" columns="1fr 4fr">
                <Box txtColor="secondary"><CheckCircle size="48" /></Box>
                <Box
                    bgColor="secondary"
                    rounded="xs"
                    height="48px"
                    margin={[0, 0, 'md', 0]}
                />
            </Grid>
            <Box
                bgColor="neutral100"
                rounded="xs"
                height="48px"
            />
        </Box>
    </Box>
);

export default BlurbForm;
