import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import ReCAPTCHA from 'react-google-recaptcha';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import PropTypes from 'prop-types';

import {
    ThemeProvider,
    Grid,
    Cell,
} from '@saladbob/sassafras';

import {
    Header, Footer, Nav, ScrollToTop,
} from './components';
import Lander from './views/Lander';
import About from './views/About';
import Faq from './views/Faq';
import Privacy from './views/Privacy';
import ResponsibleLending from './views/ResponsibleLending';
import Rates from './views/Rates';
import Ccpa from './views/Ccpa';
import Tc from './views/Tc';
import Unsub from './views/Unsub';
import Contact from './views/Contact';
import Providers from './views/ParticipatingProviders';
import Decline from './views/Decline';
import LoadingA from './views/LoadingA';
import LoadingB from './views/LoadingB';
import LoadingC from './views/LoadingC';
import LoadingSecure from './views/LoadingSecure';
import Match from './views/Match';
import Pop from './views/Pop';
import Inter from './views/Inter';
import TriviaView from './views/Trivia';

import './sass/app.scss';

const useQuery = (location) => new URLSearchParams(location.search);

const submitForm = (url, data) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;

    Object.keys(data).forEach((key) => {
        const field = document.createElement('input');
        field.type = 'hidden';
        field.name = key;
        field.value = data[key];
        form.appendChild(field);
    });

    document.body.appendChild(form);
    form.submit();
};

const nav = [{
    key: 'home',
    link: '/',
    label: 'Home',
}, {
    key: 'blog',
    href: '/blog',
    label: 'Blog',
    target: '_self',
}, {
    key: 'about',
    link: '/about',
    label: 'About Us',
}, {
    key: 'faqs',
    link: '/faqs',
    label: 'FAQs',
}, {
    key: 'contact',
    link: '/contact',
    label: 'Contact Us',
}];

const RoutesElement = ({
    brandName,
    email,
    caEmail,
    address,
    publicPath,
    imagePath,
    brandPath,
    reCaptchaKey,
    footerNav,
    fieldsUrl,
    fieldsOrder,
    submitUrl,
    P,
    o,
    e,
    fn,
    ln,
    zc,
    a,
    siteId,
    s1,
    s2,
    s3,
    luId,
    em5,
    vfy,
}) => {
    const history = useNavigate();

    const location = useLocation();

    const [fields, setFields] = useState([]);

    const recaptchaRef = React.createRef();

    const [userData, setUserData] = useState({
        rurl: `${window.location.origin}/?step=5`,
        siteId,
        e,
        fn,
        ln,
        zc,
        a,
        s1,
        s2,
        s3,
        o,
        luId,
        em5,
        vfy,
        P,
    });

    const [loading, setLoading] = useState(true);

    const query = useQuery(location);
    const qs = Number(query.get('step'));

    const [step, setStep] = useState(qs);
    // const [errMsg, setErrMsg] = useState();
    const [lastStep, setLastStep] = useState(0);

    const p = query.get('p');

    const onValidate = () => {
        setLoading(true);
    };

    const onError = () => {
        setLoading(false);
    };

    const getLocation = async (zip) => {
        const data = new FormData();

        data.append('pkey', 'PAufNMutiO4vqwQf');
        data.append('mode', 'get-cist');
        data.append('zip', zip);

        const locationData = await fetch('https://www.cashnow9.com/rt/form/', {
            method: 'POST',
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status === 'ACCEPT') {
                    return res.msg.split('||');
                }
                return [];
            }).catch(() => []);

        return locationData;
    };

    const onSubmit = async (_, data) => {
        const newUserData = { ...data };

        if (data.zip) {
            const cityState = await getLocation(data.zip);
            if (cityState.length) {
                [newUserData.city, newUserData.state] = cityState;
            }
        }

        if (data.submitForm) {
            submitForm(submitUrl, newUserData);
        }

        setUserData({ ...userData, ...newUserData });
        setLoading(false);
        setLastStep(step);
        if (step === fields.length - 1) {
            history('/match');
        } else {
            history(`/?step=${step + 1}`);
        }
    };

    const prevStep = () => {
        setLastStep(step);
        const newStep = Math.max(0, step - 1);
        history(`/?step=${newStep}`);
    };

    const onSkip = () => {
        setLastStep(Math.max(3, lastStep));
        history('/?step=3');
    };

    useEffect(() => {
        const qstep = Number(query.get('step'));

        return setStep(qstep || 0);
    }, [location]);

    // Fetch Field Data
    useEffect(() => {
        const getData = async () => {
            const fieldData = await fetch(fieldsUrl)
                .then((res) => res.json())
                .then((res) => (res.length ? res : []))
                .catch(() => []);
            if (fieldsOrder.length > 0) {
                const fieldsObj = {};
                fieldData.forEach((field) => {
                    fieldsObj[field.key] = field;
                });
                setFields(fieldsOrder.reduce((acc, key) => {
                    if (fieldsObj[key]) {
                        acc.push(fieldsObj[key]);
                    }
                    return acc;
                }, []));
            } else {
                setFields(fieldData);
            }
            setLoading(false);
        };
        getData();
    }, []);

    return (
        <Grid
            columns="3fr minmax(0px, max-content)"
            rows="minmax(min-content, max-content) 1fr auto"
            area="'header header' 'main sidebar' 'footer footer'"
        >
            <Cell area="header">
                <Header
                    logo={`${publicPath}${imagePath}${brandPath}logo.svg`}
                    logoStep={`${publicPath}${imagePath}${brandPath}logo-icon.svg`}
                    nav={(<Nav nav={nav} color="neutral800" font="title" />)}
                    navMobile={(<Nav nav={nav} color="white" font="leader" vertical />)}
                    bgColor="white"
                    step={step}
                    steps={fields.length}
                />
            </Cell>
            <Cell area="main">
                <Routes>
                    <Route
                        path="/privacy"
                        element={(
                            <Privacy
                                brandName={brandName}
                                email={caEmail}
                                address={address}
                            />
                        )}
                    />
                    <Route
                        path="/terms"
                        element={(
                            <Tc
                                brandName={brandName}
                                email={email}
                                address={address}
                            />
                        )}
                    />
                    <Route
                        path="/ccpa"
                        element={(
                            <Ccpa
                                brandName={brandName}
                                reCaptchaKey={reCaptchaKey}
                                email={caEmail}
                                address={address}
                            />
                        )}
                    />
                    <Route path="/unsub" element={(<Unsub />)} />
                    <Route path="/contact" element={(<Contact reCaptchaKey={reCaptchaKey} email={email} />)} />
                    <Route path="/about" element={(<About />)} />
                    <Route path="/faqs" element={(<Faq brandName={brandName} />)} />
                    <Route path="/responsible" element={(<ResponsibleLending brandName={brandName} />)} />
                    <Route path="/rates" element={(<Rates brandName={brandName} />)} />
                    <Route path="/providers" element={(<Providers />)} />
                    <Route path="/trivia" element={(<TriviaView />)} />
                    <Route path="/loading" element={(<LoadingA />)} />
                    <Route path="/loadingb" element={(<LoadingB />)} />
                    <Route path="/loadingc" element={(<LoadingC />)} />
                    <Route path="/loading-secure" element={(<LoadingSecure />)} />
                    <Route path="/match" element={(<Match />)} />
                    <Route path="/pop" element={(<Pop />)} />
                    <Route path="/Inter" element={(<Inter />)} />
                    <Route
                        exact
                        path="/decline"
                        element={(
                            <Decline
                                brandName={brandName}
                                imagePath={`${publicPath}${imagePath}${brandPath}`}
                                publicPath={publicPath}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/"
                        element={(
                            <>
                                <Lander
                                    brandName={brandName}
                                    fields={fields}
                                    onValidate={onValidate}
                                    onError={onError}
                                    onSubmit={onSubmit}
                                    prevStep={prevStep}
                                    onSkip={onSkip}
                                    imagePath={`${publicPath}${imagePath}${brandPath}`}
                                    step={step}
                                    lastStep={lastStep}
                                    userData={userData}
                                    loading={loading}
                                    // errMsg={errMsg}
                                    publicPath={publicPath}
                                    p={p}
                                />
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={reCaptchaKey}
                                />
                            </>
                        )}
                    />
                </Routes>
            </Cell>
            <Cell area="footer">
                <Footer
                    brandName={brandName}
                    nav={(
                        <Nav
                            nav={footerNav}
                            color="white"
                            font={{ default: 'h5', laptop: 'control' }}
                            weight="bold"
                        />
                    )}
                />
            </Cell>
        </Grid>
    );
};

const App = ({
    basename,
    publicPath,
}) => {
    const [loading, setLoading] = useState(true);
    const [theme, setTheme] = useState();

    // Fetch Theme Data
    useEffect(() => {
        const getData = async () => {
            const themeData = await fetch(`${publicPath}js/theme.json`).then((res) => res.json()).catch(() => {});
            setTheme(themeData);
            setLoading(false);
        };
        getData();
    }, []);

    return (
        <Router basename={basename}>
            <ScrollToTop />
            {theme && !loading && (
                <ThemeProvider theme={theme}>
                    <RoutesElement />
                </ThemeProvider>
            )}
        </Router>
    );
};

App.propTypes = {
    basename: PropTypes.string,
    publicPath: PropTypes.string,
};

App.defaultProps = {
    basename: window.basename || '/',
    publicPath: window.publicPath || 'public/',
};

RoutesElement.propTypes = {
    brandName: PropTypes.string,
    email: PropTypes.string,
    caEmail: PropTypes.string,
    publicPath: PropTypes.string,
    imagePath: PropTypes.string,
    brandPath: PropTypes.string,
    reCaptchaKey: PropTypes.string,
    address: PropTypes.object,
    footerNav: PropTypes.arrayOf(PropTypes.object),
    fieldsUrl: PropTypes.string,
    submitUrl: PropTypes.string,
    fieldsOrder: PropTypes.arrayOf(PropTypes.string),
    P: PropTypes.string,
    e: PropTypes.string,
    fn: PropTypes.string,
    ln: PropTypes.string,
    zc: PropTypes.string,
    a: PropTypes.string,
    siteId: PropTypes.string,
    s1: PropTypes.string,
    s2: PropTypes.string,
    s3: PropTypes.string,
    o: PropTypes.string,
    luId: PropTypes.string,
    em5: PropTypes.string,
    vfy: PropTypes.string,
};

RoutesElement.defaultProps = {
    brandName: window.brandName || 'AmazingJobs.com',
    email: window.brandEmail || 'demo@example.com',
    caEmail: window.caEmail || 'demo@example.com',
    address: window.address || {},
    publicPath: window.publicPath || 'public/',
    imagePath: window.imagePath || 'images/',
    brandPath: window.brandPath || '',
    reCaptchaKey: window.reCaptchaKey || '',
    footerNav: window.footerNav || [{}],
    fieldsUrl: window.fieldsUrl || '',
    fieldsOrder: window.fieldsOrder || [],
    submitUrl: window.submitUrl || '',
    P: window.P,
    e: window.prepopEmail,
    fn: window.prepopFN,
    ln: window.prepopLN,
    zc: window.prepopZip,
    a: window.a,
    siteId: window.siteId,
    s1: window.s1,
    s2: window.s2,
    s3: window.s3,
    o: window.o,
    luId: window.luId,
    em5: window.em5,
    vfy: window.vfy,
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
