import React from 'react';
import PropTypes from 'prop-types';
import reactParse from 'html-react-parser';

import {
    Box,
    Grid,
    Type,
} from '@saladbob/sassafras';

const HelpBox = ({
    bgColor,
    txtColor,
    image,
    text,
    publicPath = window ? window.publicPath : 'public/',
    imgPath = window ? window.imgPath : 'images/',
}) => (
    <Grid
        bgColor={bgColor}
        padding={{ phone: 'xs', laptop: 'sm' }}
        margin={[0, 0, 'md', 0]}
        rounded="xxl"
        columns={typeof image === 'string' ? { phone: '60px 1fr', laptop: '120px 1fr' } : '1fr'}
    >
        {typeof image === 'string' && (
            <Box verticalAlign="center" minHeight="100%">
                <Box
                    bgImage={`${publicPath}${imgPath}${image}`}
                    maxWidth="100%"
                    margin="auto"
                    rounded="100%"
                    bdColor={txtColor}
                    bdWidth="4px"
                    pb="calc(100% - 8px)"
                />
            </Box>
        )}
        <Box padding={{ phone: 'sm', laptop: 'md' }} align="center" txtColor={txtColor}>
            <Type font={{ phone: 'control', laptop: 'leader' }} tag="span">
                {reactParse(text)}
            </Type>
        </Box>
    </Grid>
);

HelpBox.defaultProps = {
    bgColor: 'primary600',
    txtColor: 'white',
    image: null,
    text: '',
};

HelpBox.propTypes = {
    bgColor: PropTypes.string,
    txtColor: PropTypes.string,
    image: PropTypes.string,
    text: PropTypes.string,
    publicPath: PropTypes.string,
    imgPath: PropTypes.string,
};

export default HelpBox;
