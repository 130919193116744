/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities  */
import React from 'react';
import PropTypes from 'prop-types';

import CaPortal from '../components/CaConsumerPortal';

import ContentPage from '../components/ContentPage';

const Ccpa = ({
    brandName, reCaptchaKey, email, address,
}) => (
    <ContentPage title="CALIFORNIA STATE PRIVACY NOTICE" subtitle={(<i>Effective Date:  January 1, 2022</i>)}>
        <h3>California Resident Privacy Rights</h3>
        <h4>(1) Right to Know About Personal Information Collected, Disclosed, or Sold.</h4>
        <p>a. You have the right to request that we advise you what Personal Information we collect, use, disclose, and sell that belongs to you. Personal Information does not include: (i) Publicly available information from government records; (ii) De-identified or aggregated consumer information; (iii) Information excluded from the CCPA's scope, like health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 ("HIPAA"); (iv) Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act ("FRCA"), the Gramm-Leach-Bliley Act ("GLBA"), California Financial Information Privacy Act ("FIPA"), or the Driver's Privacy Protection Act of 1994 ("DPPA").</p>
        <p>
            b. You may submit a verifiable consumer request to obtain the specific Personal Information we have collected about you either through our California Resident Portal form or you may email us at
            <a href={`mailto:${email}`}>{email}</a>
            .
        </p>
        <p>c. Upon receiving your information, we are required to verify that you are the same consumer with whom we have the information in our systems. To verify your identify, we will require that you fill out the portal form in its entirety which includes your name, address, email address and may include the last four digits of your social security number, your bank account number and other Personal Information we have collected.</p>
        <p>d. As set forth in our primary Privacy Policy, we collect several types of information about you when you visit and use our Website; examples include Personal Information, demographic information, behavioral information, and indirect information. Sometimes we collect combinations of these types of information.</p>
        <p>e. Personal Information has been collected from the following categories of sources:</p>
        <p>i. Information collected directly and indirectly from your activity on our website and your interactions or communications with us. This includes your Personal Information provided by you in connection with obtaining a certain product or service offered by us or one our network providers or advertisers.</p>
        <p>ii. Information you provide us when you enter promotions or sweepstakes offered by us or our clients including networks and advertisers.</p>
        <p>iii. Information you provide us through telephone and web-based surveys, customer service correspondence, and general feedback.</p>
        <p>iv. Information we received directly or indirectly from our clients or their agents in the course of providing services to you and others.</p>
        <p>v. Analytics companies who provide demographic information such as gender, zip code, or any information that is not tied to your Personal Information. In addition, we may receive information about you from other online or offline sources, including third-parties from whom we validate consumer self-reported information, and verify information we already have about you. This helps us to update, expand and analyze our records and provide products and services that may be of interest to you.</p>
        <p>vi. We also collect information provided by Service Providers and other third-parties as set out in our main Privacy Policy, our advertisers, ad networks and third-party technology companies.</p>
        <p>f. We have disclosed, shared and/or received compensation for our disclosure of Personal Information for the following commercial purposes in the preceding 12 months:</p>
        <p>i. To obtain the services or products you have registered for on our Website.</p>
        <p>ii. To allow a third-party to market their products or services to you.</p>
        <p>iii. To provide you with information, products or services that you request from us or resolve consumer concerns or request to opt-out of marketing. To allow a third-party to market their products or services to you.</p>
        <p>iv. To provide you with notices concerning our products or services, or events or news, that may be of interest to you.</p>
        <p>v. To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections.</p>
        <p>vi. To improve our website, products and services, and present its contents to you including testing, research, analysis and product development.</p>
        <p>vii. To personalize your Website experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Website, third-party sites, and via email or text message (with your consent, where required by law).</p>
        <p>viii. To maintain the safety, security, and integrity of our Website, platforms as well as products and services, databases and other technology assets, and business.</p>
        <p>ix. To respond to government or law enforcement requests and as required by applicable law, court order, or governmental regulations.</p>
        <p>x. To evaluate or conduct a merger, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our business or assets.</p>
        <p>xi. As described to you when collecting your Personal Information or as otherwise set forth in the CCPA.</p>
        <p>g. We have disclosed Personal Information to the following categories of third-parties:</p>
        <p>i. Our affiliated entities.</p>
        <p>ii. Our Service Providers.</p>
        <p>iii. Data aggregators.</p>
        <p>iv. Advertisers.</p>
        <p>v. Ad Networks.</p>
        <p>vi. Third-parties to whom you or your agents authorize us to disclose your Personal Information in connection with products or services we and/or third-parties provide to you.</p>
        <p>h. We do not collect, distribute, share or sell the Personal Information of anyone under the age of 16.</p>
        <h4>(2) Right to Request Deletion of Personal Information.</h4>
        <p>a. You have a right to request the deletion of your Personal Information if we have collected or maintained it in the past 12 months.</p>
        <p>
            b. You may submit a verifiable consumer request to delete your information using our California Consumer Portal and fully fill out the request form or contact us by email at
            <a href={`mailto:${email}`}>{email}</a>
            .
        </p>
        <p>c. Once we receive and confirm your verifiable consumer request, we will disclose to you the following:</p>
        <p>i. The categories of Personal Information we collected about you.</p>
        <p>ii. The categories of sources for the Personal Information we collected about you.</p>
        <p>iii. Our business or commercial purpose for collecting or selling that Personal Information.</p>
        <p>iv. The categories of third-parties with whom we shared that Personal Information.</p>
        <h4>(3) Right to Opt-Out of the Sale of Personal Information.</h4>
        <p>You have the right to opt-out of any sale of your personal information by a business. If you are requesting a product or service, this is an exemption from this right, and we will process your request. However, you have the right to cease all further sharing of information.</p>
        <h4>(4) Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights.</h4>
        <p>You have a right not to receive discriminatory treatment by us for the exercise of these privacy rights conferred by the CCPA. As such, we will not deny you goods or services, charge you different prices or rates for goods or services, or deny you discounts or other benefits; nor will we provide you a different level or quality of goods or services or recommend a different level or quality of goods or services.</p>
        <h4>(5) Authorized Agent.</h4>
        <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your Personal Information.</p>
        <h4>(6) Contact Us.</h4>
        <p>
            Contact us for more information or with any questions either through our California Consumer Portal or by email at
            <a href={`mailto:${email}`}>{email}</a>
            .
        </p>
        <h4>(7) Limitations on Requests.</h4>
        <p>You may only make a verifiable consumer request for access twice within a twelve (12) month period. The verifiable consumer request must: (a) Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or your authorized agent; (b) Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to you; (c) We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the Personal Information relates to you; (d) Making a verifiable consumer request does not require you to create an account with us. We will only use Personal Information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
        <h4>(8) Response Time and Response Format.</h4>
        <p>We make every effort to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. We we will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the twelve (12) month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons why we cannot comply with a request, if applicable. We will provide our response in a manner that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance to you.</p>
        <h4>(9) Changes to California Resident Privacy Notice.</h4>
        <p>We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will enforce the terms of the new policy going forward. You will receive no other notice than an updated posting on this page. Please check back each time you enter our Website.</p>
        <h4>(10) Contact Information.</h4>
        <p>If you have any questions or comments about this Privacy Statement, please do not hesitate to contact us at:</p>
        <p>
            Website:
            {brandName}
        </p>
        <p>
            Email:
            <a href={`mailto:${email}`}>{email}</a>
            .
        </p>

        <p>
            Address:
            {' '}
            {address.street}
            <br />
            {address.city}
            ,
            {' '}
            {address.state}
            {' '}
            {address.zip}
        </p>
        <hr />
        <h2>California Consumer Privacy Policy Preference Portal</h2>
        <h3>California Residents Rights:</h3>
        <p>The California Consumer Privacy Act of 2018 ("CCPA") provides California Residents with specific rights regarding their Personal Information. In all cases the business is required to verify the resident making the request is the same resident for which the business has collected personal information from.</p>
        <p>The options below may be selected:</p>
        <p>1. Right to access information. A California resident has the right to request disclosure of the categories and specific pieces of Personal Information the business has collected about the consumer for the previous twelve-month period.</p>
        <p>2. Right to delete information. A California resident has the right to request the business delete personal information that the business has collected about the resident.</p>
        <p>3. Right to opt-out of the sale of their Personal Information. A consumer has the right to opt-out of the sale of their personal information collected by business.</p>
        <CaPortal reCaptchaKey={reCaptchaKey} />
    </ContentPage>
);

Ccpa.propTypes = {
    brandName: PropTypes.string,
    reCaptchaKey: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.object,
};

export default Ccpa;
