import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    H2,
    Type,
    PanelSteps,
    Spinner,
    Btn,
    Grid,
} from '@saladbob/sassafras';

import Question from './Question';
import Joke from './Joke';
import Fact from './Fact';
import SubmitQuestion from './SubmitQuestion';

// slugify
const slugify = (str) => str.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

// generate UID
const uidGenerate = () => Math.random().toString(36).substring(2, 15);

function Trivia({
    questions: qs,
    submitApi,
    triviaJson,
    params,
    onSubmit: os,
}) {
    const [questions, setQuestions] = useState(qs);
    const [screen, setScreen] = useState(0);
    const [step, setStep] = useState(0);
    const [correct, setSetCorrect] = useState(0);
    const [firstQuestion, setFirstQuestion] = useState([]);
    const uid = useRef(uidGenerate());
    const lastSent = useRef();

    useEffect(() => {
        const form = new FormData();
        Object.keys(params).forEach((key) => form.append(key, params[key]));
        form.append('type', 'view');
        form.append('name', 'view');

        const getTrivia = async () => {
            await fetch(triviaJson)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }
                    return response.json();
                })
                .then((json) => {
                    setQuestions(json);
                })
                .catch(() => {
                    setQuestions([]);
                });
        };

        const getSubmitQuestions = async () => {
            await fetch(submitApi, {
                method: 'POST',
                body: form,
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error ${res.status}`);
                    }

                    return res.json();
                })
                .then((res) => {
                    if (res.status === 'ACCEPT') {
                        setFirstQuestion(res.question);
                        setScreen(1);
                    } else {
                        setScreen(1);
                    }
                })
                .catch(() => {
                    setScreen(1);
                });
        };
        getTrivia();
        getSubmitQuestions();
    }, []);

    useEffect(() => {
        setQuestions(qs);
    }, [qs]);

    const onNext = (c, next) => {
        setStep(next);
        setSetCorrect(c ? correct + 1 : correct);
    };

    const restartTrivia = () => {
        setScreen(0);
        setQuestions([]);

        setTimeout(() => {
            setStep(0);
            setQuestions(qs);
            setSetCorrect(0);
            setScreen(1 + firstQuestion.length);
        }, 300);
    };

    const onSubmit = (e, index) => {
        const { value, name } = e.target;

        if (lastSent.current === name) {
            return;
        }

        lastSent.current = name;

        const form = new FormData();

        if (typeof os === 'function') {
            os(firstQuestion.length - 1, index);
        }

        if (value === '0') { // User clicked no
            setScreen(index + 2);
        } else { // User clicked yes
            Object.keys(params).forEach((key) => form.append(key, params[key]));
            form.append('type', 'submit');
            form.append(name, value);
            form.append('name', name);

            fetch(submitApi, {
                method: 'POST',
                body: form,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }
                    setScreen(index + 2);
                })
                .catch(() => { });
        }
    };

    const submitQuestions = firstQuestion ? firstQuestion.map((question, index) => (
        <SubmitQuestion
            key={`${uid.current}-${slugify(question.q)}`}
            question={question}
            onSubmit={onSubmit}
            index={index}
        />
    )) : [];

    const triviaQuestions = [(
        <Box key="trivia-questions">
            <Box mb={{ phone: 'xs', laptop: 'md' }} align="center">
                <div style={{ width: '100%' }}>
                    <H2
                        align="center"
                        className="color-text-secondary-500"
                        maxWidth="100%"
                        font={{ phone: 'leader', laptop: 'h2' }}
                        mb="0"
                    >
                        FUN TRIVIA WHILE YOU WAIT
                    </H2>
                </div>
            </Box>
            {questions && questions.length > 0 && (
                <Box>
                    <PanelSteps step={step} pt="sm">
                        {[...questions.map((question, index) => {
                            if (question.type === 'joke') {
                                return (
                                    <Joke
                                        key={`${uid.current}-${slugify(question.q)}`}
                                        question={question.q}
                                        correctAnswer={question.a}
                                        onNext={(c) => { onNext(c, index + 1); }}
                                    />
                                );
                            }
                            if (question.type === 'fact') {
                                return (
                                    <Fact
                                        key={`${uid.current}-${slugify(question.q)}`}
                                        question={question.q}
                                        correctAnswer={question.a}
                                        onNext={(c) => { onNext(c, index + 1); }}
                                    />
                                );
                            }
                            return (
                                <Question
                                    key={`${uid.current}-${slugify(question.q)}`}
                                    question={question.q}
                                    choices={question.choices}
                                    correctAnswer={question.a}
                                    onNext={(c) => { onNext(c, index + 1); }}
                                />
                            );
                        }), (
                            <Box
                                key="final-score"
                                verticalAlign="center"
                                align="center"
                                textAlign="center"
                                padding="xxl"
                                bgColor="none"
                                rounded="sm"
                                height="300px"
                            >
                                <Type tag="p" font="h2" mb="md" align="center">
                                    YOUR FINAL SCORE
                                </Type>
                                <Type font="h5" color="primary" fw="bold" mb="lg">
                                    {correct}
                                    /
                                    {step}
                                </Type>
                                <Btn color="black" onClick={restartTrivia}>
                                    Restart Trivia
                                </Btn>
                            </Box>
                        )]}
                    </PanelSteps>
                    <Grid columns="max-content 120px" gridGap="xs" width="min-content" margin={['md', 'auto']}>
                        <Type tag="p" font="p" weight="bold" color="neutral">
                            Powered by
                        </Type>
                        <a href="https://yourdaily.buzz" target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://yourdaily.buzz/wp-content/uploads/2014/09/logo.png"
                                alt="Your Daily Buzz Logo"
                                width="100%"
                            />
                        </a>
                    </Grid>
                </Box>
            )}
        </Box>
    )];

    return (
        <Box maxWidth="640px" margin={['xl', 'auto', 0, 'auto']}>
            <PanelSteps step={screen}>
                {[(
                    <Box key="spinner" verticalAlign="center" align="center" padding="xxl">
                        <Spinner color="black" size="xl" />
                    </Box>
                ), ...submitQuestions, ...triviaQuestions]}
            </PanelSteps>
        </Box>
    );
}

Trivia.defaultProps = {
    questions: [],
    submitApi: window.triviaApi,
    triviaJson: window.triviaJson,
    params: {},
};

Trivia.propTypes = {
    questions: PropTypes.arrayOf(PropTypes.object),
    submitApi: PropTypes.string,
    triviaJson: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.string),
    onSubmit: PropTypes.func,
};

export default Trivia;
