import React from 'react';
import {
    Box,
    H3,
} from '@saladbob/sassafras';

import SearchLoader from '../components/SearchLoader';
import Trivia from '../components/trivia/Trivia';

const TriviaView = () => (
    <Box
        width="100%"
        bgColor="neutral50"
        padding={{ phone: [0, 'md'], laptop: 0 }}
    >
        <Box
            maxWidth="640px"
            margin="auto"
            minHeight="calc(100vh - 100px)"
            padding={{ phone: 'md', laptop: ['lg', 0] }}
        >
            <H3 align="center" margin={['lg', 'auto']}>Please Wait While We Connect You With a Lender</H3>
            <Box
                ms="auto"
                me="auto"
                maxWidth="320px"
            >
                <SearchLoader size={60} color="primary" speed="4s" />
            </Box>
            <Trivia />
        </Box>
    </Box>
);

export default TriviaView;
