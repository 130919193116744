import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Btn,
    FieldText,
    ValidationForm,
    Type,
    PanelSteps,
    Spinner,
    HiddenInput,
} from '@saladbob/sassafras';

const SubscribeModal = ({
    complete, affId, s1, s2, s3, s4,
}) => {
    const [step, setStep] = useState(0);
    const [msg, setMsg] = useState(false);

    const onSubmit = (e) => {
        setStep(1);
        const formData = new FormData(e);

        fetch('/api/signup/', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === '1') {
                    setTimeout(() => {
                        setStep(2);

                        if (typeof complete === 'function') {
                            complete();
                        }
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setMsg(data.msg);
                        setStep(0);

                        if (typeof complete === 'function') {
                            complete();
                        }
                    }, 1000);
                }
            });
    };

    return (
        <Box spacing="lg">
            <PanelSteps step={step}>
                <div>
                    <Type font="jumbo" tag="h2" spacingB="xs" align="center" weight="bold">
                        Don’t miss out
                        <br />
                        on your next Job!
                    </Type>
                    <Type font="h2" tag="h3" spacingB="md" align="center">
                        Get the latest jobs available in your area delivered instantly to your inbox
                    </Type>
                    {msg && (
                        <Box bgColor="darken" margin={['md', 0]} padding="sm">
                            <Type font="p" tag="p" align="center">
                                {msg}
                            </Type>
                        </Box>
                    )}
                    <ValidationForm onSubmit={onSubmit}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                maxWidth: '480px',
                                margin: '0 auto',
                            }}
                        >
                            <Box padding="sm" style={{ width: '100%' }}>
                                <FieldText
                                    name="e"
                                    rules={{ email: true, required: true }}
                                    placeholder="Your Email"
                                    borderColor="white"
                                    size="xl"
                                />
                            </Box>
                            <Box padding="sm">
                                <Btn color="black" size="xl" full>
                                    Subscribe
                                </Btn>
                            </Box>
                            <HiddenInput name="a" value={affId} />
                            <HiddenInput name="s1" value={s1} />
                            <HiddenInput name="s2" value={s2} />
                            <HiddenInput name="s3" value={s3} />
                            <HiddenInput name="s4" value={s4} />
                        </div>
                        <Type font="small" tag="p" spacingT="sm" align="center">
                            I agree to the Privacy Policy and Terms
                        </Type>
                        <Type font="small" tag="p" align="center">
                            which allow me to opt out anytime
                        </Type>
                    </ValidationForm>
                </div>
                <Box minHeight="260px" align="center" verticalAlign="center">
                    <Spinner color="white" />
                </Box>
                <Box minHeight="260px" align="center" verticalAlign="center">
                    <Type font="h3" tag="h3">
                        Thank You For Signing Up!
                    </Type>
                </Box>
            </PanelSteps>
        </Box>
    );
};

SubscribeModal.propTypes = {
    complete: PropTypes.func,
    affId: PropTypes.string,
    s1: PropTypes.string,
    s2: PropTypes.string,
    s3: PropTypes.string,
    s4: PropTypes.string,
};

SubscribeModal.defaultProps = {
    // bgColor: 'primary700',
};

export default SubscribeModal;
