import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Btn,
    FieldText,
    ValidationForm,
    Type,
    PanelSteps,
    Spinner,
    HiddenInput,
} from '@saladbob/sassafras';

const SubscribeWidget = ({
    complete, bgColor, affId, s1, s2, s3,
}) => {
    const [step, setStep] = useState(0);
    const [msg, setMsg] = useState(false);

    const onSubmit = (e) => {
        setStep(1);
        const formData = new FormData(e);

        fetch('/api/signup/', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === '1') {
                    setTimeout(() => {
                        setStep(2);

                        if (typeof complete === 'function') {
                            complete();
                        }
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setMsg(data.msg);
                        setStep(0);

                        if (typeof complete === 'function') {
                            complete();
                        }
                    }, 1000);
                }
            });
    };

    return (
        <Box bgColor={bgColor} txtColor="white" spacing="lg">
            <PanelSteps step={step}>
                <ValidationForm onSubmit={onSubmit}>
                    <Type font="h3" tag="h3" margin={[0, 0, 'xs', 0]}>
                        Stay Updated
                    </Type>
                    <Type font="h5" tag="h5" margin={[0, 0, 'sm', 0]}>
                        Get the latest jobs available instantly to your inbox
                    </Type>
                    {msg && (
                        <Box bgColor="darken" margin={[0, 0, 'sm', 0]} padding="sm">
                            <Type font="p" tag="p">
                                {msg}
                            </Type>
                        </Box>
                    )}
                    <Box mb="sm">
                        <FieldText
                            name="e"
                            rules={{ email: true, required: true }}
                            placeholder="Your Email"
                            borderColor="white"
                        />
                    </Box>
                    <Btn color="black" full>
                        Subscribe
                    </Btn>
                    <Type font="small" tag="p" margin={['sm', 0, 0, 0]}>
                        I agree to the Privacy Policy and Terms – which allow me to opt out anytime
                    </Type>
                    <HiddenInput name="a" value={affId} />
                    <HiddenInput name="s1" value={s1} />
                    <HiddenInput name="s2" value={s2} />
                    <HiddenInput name="s3" value={s3} />
                </ValidationForm>
                <Box height="260px" align="center" verticalAlign="center">
                    <Spinner color="white" />
                </Box>
                <Box height="260px" align="center" verticalAlign="center">
                    <Type font="h3" tag="h3">
                        Thank You For Signing Up!
                    </Type>
                </Box>
            </PanelSteps>
        </Box>
    );
};

SubscribeWidget.propTypes = {
    bgColor: PropTypes.string,
    affId: PropTypes.string,
    s1: PropTypes.string,
    s2: PropTypes.string,
    s3: PropTypes.string,
    complete: PropTypes.func,
};

SubscribeWidget.defaultProps = {
    bgColor: 'primary700',
};

export default SubscribeWidget;
