import React from 'react';
import {
    Type,
    Box,
} from '@saladbob/sassafras';

const Decline = () => (
    <>
        <Box
            align="center"
            bgColor="neutral50"
            padding={{ default: 'md', laptop: 'xxl' }}
            verticalAlign="center"
            minHeight="80vh"
        >
            <Box maxWidth="600px" bgColor="white" padding={{ default: 'md', laptop: 'xxl' }} depth="lg" rounded="md">
                <Type
                    align="center"
                    tag="h1"
                    font={{ default: 'h1', laptop: 'jumbo' }}
                    color="primary"
                    mb={{ default: 'sm', laptop: 'lg' }}
                >
                    Thank you for your request!
                </Type>
                <Type align="center" tag="p" font="h3">
                    We were unable to locate a lender and/or financial offer at this time.
                </Type>
            </Box>
        </Box>
    </>
);

export default Decline;
