/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities  */

import React from 'react';
import PropTypes from 'prop-types';

import ContentPage from '../components/ContentPage';

const Rates = ({ brandName }) => (
    <ContentPage title="Rates and Fees">
        <h2>
            {brandName}
            {' '}
            - Rates and Fees
        </h2>
        <p>
            Submitting your personal loan request on
            {' '}
            {brandName}
            {' '}
            is absolutely free. If you are connected
            with a third-party lender on
            {' '}
            {brandName}
            , all loan fees and interest rate information should be
            disclosed to you by your lender at the time of approval. Since
            {' '}
            {brandName}
            {' '}
            is not a lender, we cannot
            provide you with exact rates and fees of any personal loan you may receive. We encourage consumers to
            research the rules and regulations regarding personal loans, specifically the Truth in Lending Act,
            which requires lenders to fully disclose all loan fees and interest rates that borrowers may incur.
        </p>
        <h3>
            Lender&rsquo;s Disclosure of Loan Terms
        </h3>
        <p>
            If you are connected with a third party lender on
            {' '}
            {brandName}
            , the lender will likely provide
            you with documents that contain all rate and fee information regarding the loan being offered to you. It
            is important that you thoroughly review the rate and fee information provided to you by your lender prior
            to agreeing to the terms of any loan. You are under no obligation at any time to accept these loan terms
            if you determine they are unsuitable. If you wish to proceed with the loan process after deciding that
            the loan terms provided by the lender in the loan contract are acceptable, then you may electronically
            sign the lender&rsquo;s acceptance page and simply wait for your funds. It is important to remember that the
            loan interest rates, fees, and loan terms are determined solely by your lender. Because
            {' '}
            {brandName}
            is not a lender, we have no control or knowledge of the loan details between you and your lender.
        </p>
        <h3>
            APR Information
        </h3>
        <p>
            The Annual Percentage Rate, or APR, refers to the total cost of a loan on a yearly basis. It takes
            into consideration the interest rate, the costs and fees associated with the personal loan as well as
            the repayment terms. The APR of your potential loan is determined solely by the lender you connect with.
            Only the lender you connect with has control of loan terms and
            {' '}
            {brandName}
            {' '}
            does not make any
            determinations because we are not a lender or credit decision maker. Be sure to read the disclosures
            given to you by the lender carefully before you agree to the loan.
        </p>
        <h3>
            Late Payment
        </h3>
        <p>
            Lenders may have different policies regarding late payments. Please ensure that you thoroughly
            review the late payment policy detailed in the loan documents and loan contract your lender provides
            before electronically signing and thereby agreeing to the loan terms. It is important to note that all
            lenders must abide by applicable federal and state laws and regulations regarding late payment fees. You
            are encouraged to research and review the applicable laws regarding personal loans and late payments
            in the state which you reside.
        </p>
        <h3>
            Non-Payment
        </h3>
        <p>
            In the event that you are unable to repay your loan, your lender may impose additional fees or
            charges.
            {' '}
            {brandName}
            {' '}
            uses commercially reasonable efforts to only work with a network of reputable
            lenders who are committed to using fair and reasonable debt collection practices. All lenders must abide
            by applicable federal and state laws and regulations regarding collections of past due accounts. We
            encourage consumers to not accept any loans that they cannot repay.
        </p>
        <h3>
            Loan Renewal
        </h3>
        <p>
            Lenders may have different terms and policies regarding loan renewal options and it is important for you
            to thoroughly read your lender&rsquo;s loan renewal terms before signing and accepting a loan. Where
            rollovers or repayment extensions are granted, interest rates, along with any late fees and additional
            charges that may be imposed, can substantially increase the dollar amount to the end cost of your loan.
            You are encouraged to review and understand your lender&rsquo;s terms regarding loan renewal options before
            signing the loan contract.
        </p>
    </ContentPage>
);

Rates.propTypes = {
    brandName: PropTypes.string,
};

export default Rates;
