import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loader, Check } from 'react-feather';
import { Type, Box } from '@saladbob/sassafras';

import styles from './LoadingList.module.css';

const getActiveIndex = (percent, items) => {
    const itemPercent = 1 / items;
    return Math.floor(percent / itemPercent);
};

const Spinner = ({ active, finished }) => (
    <Box width="40px" height="40px" align="center">
        {active && (
            <Loader
                className={styles.spinner}
                size={36}
            />
        )}
        {finished && <Check size={32} />}
    </Box>
);

const Elipsis = () => {
    const [dots, setDots] = useState('');
    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prev) => {
                if (prev.length === 3) {
                    return '';
                }
                return `${prev}.`;
            });
        }, 500);
        return () => clearInterval(interval);
    }, [dots]);

    return dots;
};

const LoadingList = ({ percent, items }) => {
    const activeIndex = getActiveIndex(percent, items.length);

    const theActiveIndex = useMemo(() => activeIndex, [activeIndex, items.length]);

    return items.map((item, index) => (
        <Type
            key={item}
            font="h3"
            tag="h3"
            txtColor={index <= theActiveIndex ? 'white' : 'rgba(255, 255, 255, 0.3)'}
            icon={<Spinner active={index === theActiveIndex} finished={index < theActiveIndex} />}
        >
            {item}
            {index === theActiveIndex && <Elipsis />}
        </Type>
    ));
};

Spinner.propTypes = {
    active: PropTypes.bool,
    finished: PropTypes.bool,
};

LoadingList.propTypes = {
    percentage: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.node),
};

export default LoadingList;
