import React, { useState, useEffect } from 'react';
import {
    Box, ProgressBar,
} from '@saladbob/sassafras';

import SearchLoader from './SearchLoader';

const BlurbForm = () => {
    const [progress, updateProgress] = useState(0);

    useEffect(() => {
        if (progress > 1) {
            setTimeout(() => {
                updateProgress(0.01);
            }, 1000);
        } else {
            setTimeout(() => {
                updateProgress(progress + 0.005);
            }, 50);
        }
    }, [progress]);

    return (
        <Box margin={[0, 0, 'xl', 0]}>
            <Box
                rounded="sm"
                padding="md"
                depth="xxl"
                maxWidth="320px"
                margin={[0, 'auto', 'lg', 'auto']}
            >
                <SearchLoader speed="5s" color="secondary" />
                <ProgressBar
                    bgColor="neutral100"
                    progressColor="secondary"
                    progress={progress}
                    transition={false}
                    rounded="lg"
                    display="block"
                />
            </Box>
        </Box>
    );
};

export default BlurbForm;
