import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Search } from 'react-feather';
import { createUseStyles } from 'react-jss';
import {
    Box, ThemeContext,
} from '@saladbob/sassafras';

const styles = createUseStyles({
    '@keyframes Grow1': {
        '0%': {
            transform: 'scale(2)',
        },
        '10%': {
            transform: 'scale(2)',
        },
        '30%': {
            transform: 'scale(1)',
        },
        '80%': {
            transform: 'scale(1)',
        },
        '95%': {
            transform: 'scale(2)',
        },
        '100%': {
            transform: 'scale(2)',
        },
    },
    '@keyframes Grow2': {
        '0%': {
            transform: 'scale(1)',
        },
        '10%': {
            transform: 'scale(1)',
        },
        '20%': {
            transform: 'scale(2)',
        },
        '32%': {
            transform: 'scale(1)',
        },
        '68%': {
            transform: 'scale(1)',
        },
        '80%': {
            transform: 'scale(2)',
        },
        '88%': {
            transform: 'scale(1)',
        },
    },
    '@keyframes Grow3': {
        '0%': {
            transform: 'scale(1)',
        },
        '20%': {
            transform: 'scale(1)',
        },
        '30%': {
            transform: 'scale(2)',
        },
        '45%': {
            transform: 'scale(1)',
        },
        '60%': {
            transform: 'scale(1)',
        },
        '70%': {
            transform: 'scale(2)',
        },
        '85%': {
            transform: 'scale(1)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    },
    '@keyframes Grow4': {
        '0%': {
            transform: 'scale(1)',
        },
        '30%': {
            transform: 'scale(1)',
        },
        '40%': {
            transform: 'scale(2)',
        },
        '60%': {
            transform: 'scale(2)',
        },
        '70%': {
            transform: 'scale(1)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    },
    '@keyframes Slide': {
        '0%': {
            left: '44px',
            top: '4px',
        },
        '50%': {
            left: 'calc(100% - 36px)',
            top: '4px',
        },
        '100%': {
            left: '44px',
            top: '4px',
        },
    },
    SearchAnimation: {
        width: '100%',
        padding: '0 36px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& div': {
        },
        '& div:nth-child(1)': {
            animation: ({ speed }) => `$Grow1 ${speed} cubic-bezier(0.5, 0, 0.5, 1) infinite`,
        },
        '& div:nth-child(2)': {
            animation: ({ speed }) => `$Grow2 ${speed} cubic-bezier(0.5, 0, 0.5, 1) infinite`,
        },
        '& div:nth-child(3)': {
            animation: ({ speed }) => `$Grow3 ${speed} cubic-bezier(0.5, 0, 0.5, 1) infinite`,
        },
        '& div:nth-child(4)': {
            animation: ({ speed }) => `$Grow4 ${speed} cubic-bezier(0.5, 0, 0.5, 1) infinite`,
        },
    },
    Dot: {
        display: 'block',
        width: '12px',
        height: '12px',
        borderRadius: '100%',
        backgroundColor: ({ theme, color }) => theme.colors[color],
    },
    SearchIcon: {
        position: 'absolute',
        transform: 'translateX(-50%)',
        animation: ({ speed }) => `$Slide ${speed} cubic-bezier(0.5, 0, 0.5, 1) infinite`,
    },
});

const SearchLoader = ({ speed, size, color }) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, color, speed });

    return (
        <Box
            margin={[0, 0, 'md', 0]}
            height={`${size}px`}
            justify="center"
        >
            <div className={classes.SearchAnimation}>
                <div className={classes.Dot} />
                <div className={classes.Dot} />
                <div className={classes.Dot} />
                <div className={classes.Dot} />
                <div className={classes.SearchIcon}>
                    <Search size={`${size}`} />
                </div>
            </div>
        </Box>
    );
};

SearchLoader.propTypes = {
    color: PropTypes.string,
    speed: PropTypes.string,
    size: PropTypes.number,
};

SearchLoader.defaultProps = {
    color: 'secondary',
    speed: '5s',
    size: 120,
};

export default SearchLoader;
