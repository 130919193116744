export default function freeLoader(tm, cb, rscb, rs, fin) {
    let time = tm * 100;
    let progressInterval;
    const startime = time;
    const restart = typeof rs === 'boolean' ? rs : true;

    function trailingZero(myNum) {
        if (myNum < 10) {
            return `0${myNum}`;
        }
        return myNum;
    }

    function leadingZero(myNum) {
        if (myNum && !Number.isNaN(myNum)) {
            return parseFloat(myNum).toFixed(1);
        }
        return 0;
    }

    function callBack() {
        const minutes = Math.floor(time / 6000);
        const seconds = Math.floor((time - minutes * 6000) / 100);
        const milseconds = Math.floor(time - (minutes * 6000) - (seconds * 100));

        cb({
            min: minutes,
            sec: seconds,
            minTz: trailingZero(minutes),
            secTz: trailingZero(seconds),
            ms: trailingZero(milseconds),
            percent: (startime - time) / startime,
            hrPercent: trailingZero(leadingZero(((startime - time) / startime) * 100)),
        });
    }

    function increment() {
        time -= 1;
        if (time < 0) {
            window.clearInterval(progressInterval);
            if (restart) {
                if (typeof rscb === 'function') {
                    rscb();
                }
                freeLoader(tm, cb, rscb, rs, fin);
            } else if (typeof fin === 'function') {
                fin();
            }
        } else {
            callBack();
        }
    }

    progressInterval = window.setInterval(increment, 10);
}
