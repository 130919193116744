/* max-len: 0 */
/* react/no-unescaped-entities: 0 */

import React from 'react';
import PropTypes from 'prop-types';

import ContentPage from '../components/ContentPage';

const ResponsibleLending = ({ brandName }) => (
    <ContentPage title="Responsible Lending">
        <h2>
            {brandName}
            {' '}
            - Responsible Lending
        </h2>
        <p>
            {brandName}
            {' '}
            believes in creating an informed marketplace so that consumers can be educated
            on the products and services they desire and can make responsible decisions about what products
            or services are best for them. We only work with and invite reputable lenders to our network.
            {brandName}
            {' '}
            conducts thorough compliance audits of the lenders in our network to ensure
            they are in compliance with all applicable lending laws and does not hesitate to cease conducting
            business with any company who isn`&apos;t. You are encouraged to contact us with any questions.
        </p>
        <h3>Truth In Lending Act</h3>
        <p>
            Loan terms will vary among different Lenders, such as the loan amount, the APR for the loan,
            costs of the loan, and the repayment plan. Lenders are required by law to present to you the
            terms of the loan, the rates and all costs associated with the loan prior to your execution of
            the loan documents. It is important that you thoroughly review the rate and fee information
            provided to you by your lender prior to agreeing to the terms of any loan. You are under no
            obligation at any time to accept these loan terms if you determine they are unsuitable. It is
            important to remember that the loan interest rates, fees, and loan terms are determined solely
            by your lender. Because
            {' '}
            {brandName}
            {' '}
            is not a lender, we have no control or knowledge of
            the loan details between you and your lender.
        </p>
        <h3>Fair Lending</h3>
        <p>
            {brandName}
            {' '}
            supports all provisions of the Fair Lending Act, which require lenders to
            afford to consumers fair, equitable, and nondiscriminatory access to credit. Further, The
            Dodd-Frank Wall Street Reform Act proscribes that all lenders must engage in &#39;fair lending,&#39;
            which is defined as &#39;fair, equitable, and nondiscriminatory access to credit for consumers.&#39;
            Dodd-Frank also tasks the Consumer Financial Protection Bureau with the enforcement of fair
            lending regulations. We encourage you to reach out directly to your lender with further
            questions.
        </p>
        <h3>Fair Debt Collection Practices</h3>
        <p>
            {brandName}
            {' '}
            supports all provisions of the Fair Debt Collection Practices Act enforced by
            the Federal Trade Commission. Because
            {brandName}
            {' '}
            is not a lender, does not broker loans,
            and does not make credit decisions, our company does not engage in debt collection from
            consumers. However,
            {brandName}
            {' '}
            strives to always and only work with lenders that are in
            compliance with any and all applicable debt collection laws, including but not limited to: (i)
            Not contacting consumers by phone any time before 8 a.m. or after 9 p.m.; (ii) Not contacting
            consumers by phone with the intent to annoy, harass, or use abusive language.; (iii) Not
            collecting debt through deception or misrepresentation; and (iv) Not threatening legal recourse
            when no legal action is permitted.
        </p>
    </ContentPage>
);

ResponsibleLending.propTypes = {
    brandName: PropTypes.string,
};

export default ResponsibleLending;
