import React from 'react';
import {
    Type,
    Box,
} from '@saladbob/sassafras';

const Providers = () => (
    <Box
        align="center"
        padding={['xl', 'lg']}
        verticalAlign="center"
    >
        <Box maxWidth="960px" margin="auto">
            <Type tag="h1" font="h1" mb="xl">LIST OF MARKETING RELATIONSHIPS</Type>

            <Type tag="h2" font="h2" mb="xl">The following marketing partners listed below may contact you:</Type>

            <Type tag="h3" font="h3" mb="sm">A</Type>
            <Type tag="p" mb="md">
                ActiveProspect
                <br />
                AdvanceAmerica
                <br />
                Advocate Debt Relief, LLC
                <br />
                Affinity Mortgage
                <br />
                AMEC
                <br />
                American Bank Mortgage Group
                <br />
                American Debt Solutions
                <br />
                American Dream Mortgage
                <br />
                American Equity Mortgage, Inc.
                <br />
                American Financial Resources, Inc.
                <br />
                American Mortgage and Equity Consultants, Inc.
                <br />
                American Pacific Mortgage
                <br />
                American Preferred Lending
                <br />
                Americash
                <br />
                AmeriFund Funding Corporation
                <br />
                AmeriPro Funding, Inc.
                <br />
                Amerisave Mortgage Corporation
                <br />
                AMIC Advance Mortgage & Investment Co.
                <br />
                AmOne
                <br />
                Approval Plus Mortgage Services
                <br />
                Arbor Mortgage Corporation
                <br />
                Arcstone Financial
                <br />
                Arizona State Credit Union
                <br />
                Avant
                <br />
                Avio Credit (Curo Financial)
                <br />
                Ascend Funding
                <br />
                Ascent
                <br />
                Auto Approve
                <br />
                Automobile Consumer Services, Inc.
                <br />
                Avant
                <br />
                AWL
                <br />
                Axos Bank
                <br />
                Advance Financial
                <br />
                Americash
                <br />
                Americor Funding
                <br />
                Approved Cash Advance
                <br />
                Arrowshade
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">B</Type>
            <Type tag="p" mb="md">
                Bankers Mortgage Corporation
                <br />
                Barrington Bank
                <br />
                Bay Equity (Rich Wilde)
                <br />
                Bay Equity, LLC
                <br />
                Blue Sky
                <br />
                BNC National Bank
                <br />
                Bondcorp Realty Services Inc.
                <br />
                Brazos National Bank
                <br />
                Brickwood Mortgage Inc.
                <br />
                Bridgeview Bank Mortgage Company
                <br />
                Balboa Capital
                <br />
                Bank of America
                <br />
                Bankit Capital
                <br />
                Bankers Healthcare Group
                <br />
                BBVA
                <br />
                Beacon Community Credit Union
                <br />
                Better Day Loans
                <br />
                BFS Capital
                <br />
                Big Think Capital
                <br />
                Bluevine
                <br />
                Boatloan.com
                <br />
                Borrow Works
                <br />
                BorrowersFirst, Inc.
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">C</Type>
            <Type tag="p" mb="md">
                C & R Mortgage
                <br />
                CapWest Mortgage Corp
                <br />
                Cardinal Financial
                <br />
                Carvana
                <br />
                Cash By Phone, LLC
                <br />
                Certified Funding Capital Corporation
                <br />
                Clear One
                <br />
                Colossus Media, LLC
                <br />
                Community Federal Savings Bank (CFSB)
                <br />
                Community Home Lending, Inc
                <br />
                Consolidated Credit
                <br />
                Consumers Alliance Processing Corporation
                <br />
                Credible
                <br />
                Credimac
                <br />
                CreditAssist.com
                <br />
                CreditAssociates
                <br />
                Credit Fresh
                <br />
                Cross Country Mortgage
                <br />
                Cura Debt
                <br />
                Credibly
                <br />
                CreditFresh
                <br />
                CreditNinja
                <br />
                Cure LLC
                <br />
                Currency Capital
                <br />
                Cashlane
                <br />
                Cash Central
                <br />
                Cashcall
                <br />
                Cash for Whatever
                <br />
                Cashlink USA
                <br />
                Choice Capital Funding
                <br />
                Clean Slate Finance
                <br />
                Clear One Advantage
                <br />
                Comet Loans
                <br />
                Commerce Capital
                <br />
                Credible
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">D</Type>
            <Type tag="p" mb="md">
                DAS Acquisition Company LLC
                <br />
                Debt Solution One
                <br />
                Debt Vantage
                <br />
                DHA Financial, Inc.
                <br />
                Discountloans.com
                <br />
                Discover Home Loans
                <br />
                Ditech.com
                <br />
                DLJ Financial
                <br />
                DMB Financial
                <br />
                Dollar Connect
                <br />
                Dominion Capital Mortgage Inc.
                <br />
                Dakota Lending
                <br />
                Devwire
                <br />
                Discover
                <br />
                DreamSpring
                <br />
                Drivetime
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">E</Type>
            <Type tag="p" mb="md">
                Eagle Home Mortgage
                <br />
                Efast Funding (Amcap)
                <br />
                Encompass Lending Group
                <br />
                Enterprise Bank
                <br />
                Envoy Mortgage Ltd.
                <br />
                Even Financial
                <br />
                EverBank
                <br />
                Experian
                <br />
                Earnest Inc
                <br />
                Elevation Capital
                <br />
                eMoney USA
                <br />
                Essential Lending
                <br />
                Essex Credit
                <br />
                Even Financial
                <br />
                Evergreen Services
                <br />
                Expansion Capital Group
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">F</Type>
            <Type tag="p" mb="md">
                Fairview Mortgage Corp.
                <br />
                Federated Mortgage Corp.
                <br />
                FinanceMyHome.com
                <br />
                Finance of America Mortgage
                <br />
                Financial Integrity Group
                <br />
                First American Wholesale Lending
                <br />
                First Chance Mortgage Inc.
                <br />
                First Choice Advocacy, LLC
                <br />
                First Choice Lending Services, LLC
                <br />
                First Choice Management LLC
                <br />
                First Direct Lending, LLC
                <br />
                First Federal Bank
                <br />
                First Financial Services, Inc.
                <br />
                First Premier Credit
                <br />
                Fortren Funding, LLC
                <br />
                Freedom Debt Relief
                <br />
                Freedom Mortgage Corporation
                <br />
                Freedom Plus
                <br />
                Fresh Data LLC
                <br />
                Frontline Financial
                <br />
                Finance Factory
                <br />
                First Loan
                <br />
                Freedom Cash
                <br />
                Funding Circle
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">G</Type>
            <Type tag="p" mb="md">
                Gateway Mortgage Group, LLC
                <br />
                Geneva Financial, LLC
                <br />
                Georgetown Mortgage
                <br />
                Gershfeld Law Group
                <br />
                GoodMortgage.com
                <br />
                Grand Bank, N.A.
                <br />
                Great Western Financial Services
                <br />
                Greenlink Financial
                <br />
                Greentree Mortgage Company
                <br />
                Greenwise Financial Solutions
                <br />
                Griffin Funding, Inc
                <br />
                Guaranteed Rate Inc.
                <br />
                Guardian Mortgage Direct
                <br />
                GVG Capital
                <br />
                Green Arrow
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">H</Type>
            <Type tag="p" mb="md">
                Heartland Home Mortgage
                <br />
                Help Advisor
                <br />
                Heritage Mortgage Banking
                <br />
                Hippo Financial
                <br />
                HomePlus Mortgage
                <br />
                Homesite Mortgage
                <br />
                Hunter Financial Group, LLC
                <br />
                Headway Capital
                <br />
                Helix Financial
                <br />
                Help me Ride
                <br />
                HESAA
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">I</Type>
            <Type tag="p" mb="md">
                iAdvance Now Inc
                <br />
                Idea Financial
                <br />
                iLending Direct
                <br />
                iLoan
                <br />
                iFreedom Direct
                <br />
                Inbox Credit
                <br />
                Inbox Loan
                <br />
                In Box We Trust, LLC
                <br />
                Integrity Home Loan of Central Florida, Inc
                <br />
                Integrity Mortgage Group
                <br />
                Intimate Interactive
                <br />
                Intuitive Home Loans
                <br />
                Iron Harbor Mortgage, LLC
                <br />
                iServe Residential Lending
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">J</Type>
            <Type tag="p" mb="md">
                Jora Credit
                <br />
            </Type>
            <Type tag="h3" font="h3" mb="sm">K</Type>
            <Type tag="p" mb="md">
                Keystone Mortgage
                <br />
                Kiel
                <br />
                KingofKash
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">L</Type>
            <Type tag="p" mb="md">
                Lakeview Mortgage
                <br />
                Landmark Management Group
                <br />
                Lead Pie
                <br />
                LeadQual
                <br />
                LEadVerifier
                <br />
                LeadZoom
                <br />
                Legal Center For Debt
                <br />
                LenderFI, Inc.
                <br />
                LendingClub
                <br />
                LendingPoint
                <br />
                Lending Tree
                <br />
                Lendmark Financial
                <br />
                Lend Smart Mortgage, LLC
                <br />
                Lexington Law
                <br />
                Lexington Consumer Advocates
                <br />
                Liberty Lending Consultants
                <br />
                Liberty Lending Group
                <br />
                Lighthouse Finance Solutions
                <br />
                Lightstream
                <br />
                Loanbright.com
                <br />
                loanDepot.com
                <br />
                Loan Exchange
                <br />
                LoanMart
                <br />
                Loan Me
                <br />
                Loanry
                <br />
                LSI Mortgage Plus
                <br />
                Loans At Last
                <br />
                Loanz
                <br />
                Loop Fund
                <br />
                Lend you Cash
                <br />
                LendersEdge
                <br />
                Lending Club
                <br />
                LendKey
                <br />
                Lendistry
                <br />
                LoanMart
                <br />
                Loan Cent
                <br />
                Loanme
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">M</Type>
            <Type tag="p" mb="md">
                M & M Mortgage, LLC
                <br />
                Mann Mortgage, LLC
                <br />
                Masterman Roche, Inc. (The Huntington Group)
                <br />
                Mediator Debt Solutions
                <br />
                Mediator Law Group
                <br />
                Michigan Mutual (MO Branch)
                <br />
                Mid-Island Mortgage Corp.
                <br />
                Millenium Home Mortgage, LLC
                <br />
                M Mark High
                <br />
                Monevo Inc
                <br />
                Money Key
                <br />
                Mortgage Lenders of America, LLC
                <br />
                Mount Olympus Mortgage Company
                <br />
                Marcus  by Goldman Sachs
                <br />
                Marine Credit Union
                <br />
                Mint Servicing
                <br />
                Mariner Finance
                <br />
                Minto
                <br />
                Mitig
                <br />
                Monevo
                <br />
                Money Stash
                <br />
                Money Key
                <br />
                Money Lion
                <br />
                My Car Plus Loan
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">N</Type>
            <Type tag="p" mb="md">
                National Debt Relief
                <br />
                National Funding
                <br />
                National Small Loan
                <br />
                National Settlement Services
                <br />
                Nationstar Mortgage (Greenlight Loans)
                <br />
                Nationwide Student Loan
                <br />
                NetCredit
                <br />
                NetDebt LLC
                <br />
                Network Capital Funding Corp.
                <br />
                Network Funding LP
                <br />
                Network Funding LP (Jesse Sadowski)
                <br />
                New American Funding
                <br />
                New Direction
                <br />
                New Era Debt Solutions
                <br />
                NewLeaf
                <br />
                New Penn Financial, LLC
                <br />
                NFC Mortgage Company, LLC
                <br />
                Norcom Mortgage
                <br />
                North American Financial Corp
                <br />
                North American Savings Bank
                <br />
                National Funding
                <br />
                National Small Loan
                <br />
                NetCredit
                <br />
                New Roads Auto
                <br />
                Nextwave Funding
                <br />
                Noble Funding
                <br />
                North Ave. Capital
                <br />
                Northstar Credit Union
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">O</Type>
            <Type tag="p" mb="md">
                Oakstar Bank
                <br />
                Oak View Law Group
                <br />
                Offer Edge
                <br />
                One Main Financial
                <br />
                Opportunity Financial
                <br />
                Optimum First Mortgage, Inc.
                <br />
                OnDeck
                <br />
                One Main Financial
                <br />
                Opp Loans
                <br />
                Oxford Financial
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">P</Type>
            <Type tag="p" mb="md">
                Pacific Home Loans, Inc.
                <br />
                Pacific Union Financial, LLC
                <br />
                Paramount Equity Mortgage
                <br />
                Pave
                <br />
                Payment Debt Relief Inc
                <br />
                PayOff
                <br />
                Peachtree Debt Relief
                <br />
                People&apos;s Bank & Trust
                <br />
                Peoples Home Equity, Inc
                <br />
                Peoples Home Lending
                <br />
                Peoples Mortgage Company
                <br />
                PERL Mortgage
                <br />
                Planet Home Lending
                <br />
                Platinum Financial Funding
                <br />
                Platinum Home Mortgage Corporation
                <br />
                PMAC Lending Services, Inc.
                <br />
                Potomac Trust Mortgage Company
                <br />
                Prime Source Mortgage, Inc
                <br />
                Proficio Mortgage Ventures
                <br />
                Prosper
                <br />
                Providential Bancorp
                <br />
                Pave
                <br />
                Payoff
                <br />
                Pearl Capital
                <br />
                PowerLend
                <br />
                Prestige Financial
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">Q</Type>
            <Type tag="p" mb="md">
                Quicken Loans
                <br />
                QuinStreet
                <br />
                Quote Velocity
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">R</Type>
            <Type tag="p" mb="md">
                Ran Wild Productions
                <br />
                Rapid Advance
                <br />
                Rapid Finance
                <br />
                Rate Genius
                <br />
                RateWinner, LLC
                <br />
                Real Estate Mortgage Network (Erik Startzel)
                <br />
                Regional Finance
                <br />
                Rushmore
                <br />
                Reliance First Capital, LLC
                <br />
                Reliant Bank
                <br />
                Republic Finance
                <br />
                Right Start Mortgage, Inc.
                <br />
                RISE
                <br />
                RMC Vanguard Mortgage Corporation
                <br />
                RoundPoint Mortgage Company
                <br />
                Royal United Mortgage, Inc
                <br />
                Reglional Finance
                <br />
                Reliable Credit
                <br />
                Resolvly
                <br />
                Riverbend Cash
                <br />
                Rocketloans
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">S</Type>
            <Type tag="p" mb="md">
                Sanco (Loan Me)
                <br />
                Second Generation Mortgage Group, LLC
                <br />
                Security America Mortgage
                <br />
                Security Finance
                <br />
                Security Mortgage Lenders Inc.
                <br />
                Shea Mortgage
                <br />
                Signature Servicing
                <br />
                Silver Fin Capital Group, LLC
                <br />
                SmartMortgage.com
                <br />
                SmartMortgage.com_exclusive
                <br />
                SolveDebts
                <br />
                Southwest Direct Mortgage LLC
                <br />
                Southwest Funding CO
                <br />
                Southwest Funding LP
                <br />
                Speedy Cash
                <br />
                Springleaf
                <br />
                Square one Financial Group
                <br />
                Start New Settlement
                <br />
                Sterling National Mortgage Company
                <br />
                Sterling Savings Bank
                <br />
                SunTrust Mortgage
                <br />
                Summit Direct Mail
                <br />
                Summit Mortgage Corporation (Scott Miller)
                <br />
                Sutherland Mortgage Services, Inc.
                <br />
                Swan Financial Corporation
                <br />
                Synergy One Lending
                <br />
                Sallie Mae,
                <br />
                Sanco Buisness
                <br />
                SBG Funding
                <br />
                et Financial
                <br />
                Sierra
                <br />
                Soaren
                <br />
                Sofi
                <br />
                SOS Capital
                <br />
                Suntrust
                <br />
                Stone Lake Lending
                <br />
                SmartBiz
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">T</Type>
            <Type tag="p" mb="md">
                The Champion Bank
                <br />
                The Huntington National Bank
                <br />
                TIAA Direct
                <br />
                Tidewater Home Mortgage Group Inc.
                <br />
                TJC Mortgage
                <br />
                Top Flite Financial, Inc.
                <br />
                TruHome Solutions
                <br />
                TTC Marketing Solutions
                <br />
                Twillio
                <br />
                Tall Grass Financial
                <br />
                TanOak
                <br />
                Tiger Merchant Funding
                <br />
                Total Loan Company
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">U</Type>
            <Type tag="p" mb="md">
                U.S. MortgageLine
                <br />
                United Pacific Mortgage
                <br />
                Upstart
                <br />
                USAA
                <br />
                Ubi Cash
                <br />
                Unify Lenders
                <br />
                Upgrade Inc
                <br />
                Uplyft Capital
                <br />
                Upstart Network Inc
                <br />
                US Business Funding
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">V</Type>
            <Type tag="p" mb="md">
                Vanguard Funding
                <br />
                Vantage Finance
                <br />
                Vantage Point Bank
                <br />
                Verify Connect
                <br />
                Viewridge Funding
                <br />
                Veritas Funding, LLC
                <br />
                Veterans First Mortgage
                <br />
                Volunteer Mortgage Inc.
                <br />
                Vouch Financial, Inc
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">W</Type>
            <Type tag="p" mb="md">
                WCS Lending
                <br />
                West Coast Funding
                <br />
                WestStar Mortgage, Inc.
                <br />
                White Label Dating
                <br />
                Willamette Valley Bank
                <br />
                Wintrust Mortgage
                <br />
                World Finance
                <br />
                Wyndham Capital Mortgage
                <br />
                Western Perferred Financial
                <br />
                Westlake Direct, LLC
                <br />
                Wilshire Consumer Debt
                <br />
                WLCC Lending
                <br />
                World Finance
                <br />
            </Type>

            <Type tag="h3" font="h3" mb="sm">X</Type>

            <Type tag="h3" font="h3" mb="sm">Y</Type>

            <Type tag="h3" font="h3" mb="sm">Z</Type>
            <Type tag="p" mb="md">
                ZeroPark
                <br />
                Zions First National Bank
                <br />
                Zippy Loans
                <br />
                Ziibi
                <br />
            </Type>
        </Box>
    </Box>
);

export default Providers;
