import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'react-feather';
import { createUseStyles } from 'react-jss';
import {
    LazyMotion,
    domAnimation,
    m,
    AnimatePresence,
} from 'framer-motion';

import {
    BtnDot, Box,
} from '@saladbob/sassafras';

const styles = createUseStyles({
    Overlay: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.15)',
        zIndex: 9000,
        border: 'none',
        padding: 0,
        margin: 0,
    },
    Modal: {
        display: 'flex',
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
        width: 'calc(100% - 32px)',
        height: 'calc(100% - 32px)',
        maxWidth: '740px',
        maxHeight: '540px',
        zIndex: 9000,
    },
    CloseBtn: {
        position: 'absolute',
        top: -16,
        right: -16,
        zIndex: 2,
    },
});

const Modal = ({
    bgImage, bgColor, txtColor, btnColor, show, onClose, children,
}) => {
    const classes = styles();

    const close = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    return (
        <AnimatePresence>
            {show && (
                <LazyMotion features={domAnimation}>
                    <m.div
                        onClick={close}
                        initial={{
                            opacity: 0,
                            y: 24,
                        }}
                        animate={{
                            opacity: 1,
                            y: 0,
                        }}
                        exit={{
                            opacity: 0,
                        }}
                        transition={{ duration: 0.3 }}
                    >
                        <button
                            className={classes.Overlay}
                            onClick={close}
                            type="button"
                            aria-label="Close Modal"
                        />
                        <div className={classes.Modal}>
                            <div className={classes.CloseBtn}>
                                <BtnDot color={btnColor} onClick={close} aria-label="Close Modal">
                                    <X />
                                </BtnDot>
                            </div>
                            <Box
                                bgColor={bgColor}
                                txtColor={txtColor}
                                verticalAlign="center"
                                align="center"
                                width="100%"
                                minHeight="100%"
                                bgImage={bgImage}
                                bgPosition="left bottom"
                                bgSize="52%"
                            >
                                {children}
                            </Box>
                        </div>
                    </m.div>
                </LazyMotion>
            )}
        </AnimatePresence>
    );
};

Modal.propTypes = {
    children: PropTypes.node,
    bgImage: PropTypes.string,
    bgColor: PropTypes.string,
    txtColor: PropTypes.string,
    btnColor: PropTypes.string,
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

Modal.defaultProps = {
    bgColor: 'primary800',
    txtColor: 'white',
    btnColor: 'black',
};

export default Modal;
