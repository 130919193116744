import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
    Type,
    Box,
    ThemeContext,
} from '@saladbob/sassafras';

const styles = createUseStyles({
    ContentPage: ({ theme }) => ({
        '& p': {
            marginBottom: theme.spacing.sm,
            ...theme.typography.p,
        },
        '& h1': {
            marginBottom: theme.spacing.lg,
            ...theme.typography.h1,
        },
        '& h2': {
            marginBottom: theme.spacing.md,
            ...theme.typography.h2,
        },
        '& h3': {
            marginBottom: theme.spacing.sm,
            ...theme.typography.h3,
        },
        '& h4': {
            marginBottom: theme.spacing.sm,
            ...theme.typography.h4,
        },
        '& h5': {
            marginBottom: theme.spacing.sm,
            ...theme.typography.h5,
        },
        '& h6': {
            marginBottom: theme.spacing.sm,
            ...theme.typography.h6,
        },
    }),
});

const ContentPage = ({ title, subtitle, children }) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme });
    return (
        <>
            <Box
                align="center"
                bgColor="darkBlue"
                padding="xxl"
                verticalAlign="center"
            >
                <Box maxWidth="960px">
                    <Type tag="h1" font="jumbo" color="primary100">
                        {title}
                    </Type>
                    {subtitle && (
                        <Type tag="h2" font="h3" color="white" weight="normal">
                            {subtitle}
                        </Type>
                    )}
                </Box>
            </Box>
            <Box
                align="center"
                padding={['xl', 'lg']}
                verticalAlign="center"
            >
                <Box maxWidth="960px" margin="auto" className={classes.ContentPage}>
                    {children}
                </Box>
            </Box>
        </>
    );
};

ContentPage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.node,
};

export default ContentPage;
