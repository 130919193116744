import React from 'react';
import PropTypes from 'prop-types';
import {
    Type,
} from '@saladbob/sassafras';

import styles from '../styles/Spinner.styles.css';

const Spinner = ({
    color,
}) => (
    <Type color={color}>
        <div className={styles.spinner}>
            <div />
            <div />
            <div />
            <div />
        </div>
    </Type>
);

Spinner.propTypes = {
    color: PropTypes.string,
};

export default Spinner;
