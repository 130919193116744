import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    H2,
    H3,
    FieldRadio,
} from '@saladbob/sassafras';

import { Check, X } from 'react-feather';

const Question = ({
    question,
    choices,
    correctAnswer,
    onNext,
    slideTime,
}) => {
    const [answer, setAnswer] = useState();

    const onClick = (a) => {
        if (typeof onNext === 'function') {
            onNext(a === correctAnswer);
        }
    };

    const onChange = (e) => {
        setAnswer(e.target.value);
        setTimeout(() => { onClick(e.target.value); }, slideTime || 1800);
    };

    useEffect(() => {}, [answer]);

    return (
        <Box>
            <H2 font="h5" mb="md" align="center" weight="bold">{question}</H2>
            <Box maxWidth="420px" margin="auto">
                <FieldRadio
                    options={
                        answer
                            ? choices.map((choice) => ({
                                label: choice,
                                value: choice,
                                uncheckedColor: choice === correctAnswer ? 'success' : 'neutral',
                                checkedColor: choice === correctAnswer ? 'success' : 'red',
                                iconChecked: choice === correctAnswer ? (<Check />) : <X />,
                                uncheckedOutline: choice !== correctAnswer,
                            }))
                            : choices.map((choice) => ({
                                value: choice,
                                label: choice,
                                uncheckedColor: '',
                            }))
                    }
                    onChange={onChange}
                    disabled={answer}
                    columnGap="md"
                    rowGap="md"
                    radioSize="sm"
                    rounded="lg"
                    uncheckedColor="primary"
                    uncheckedClassName="color-bg-secondary-500"
                />
            </Box>
            {answer && (
                <Box display="block" textAlign="center">
                    <H3 font="p" mb="md" align="center" color="neutral700">
                        {answer === correctAnswer
                            ? 'You Got It!'
                            : `You missed it, the correct answer was ${correctAnswer}`}
                    </H3>
                </Box>
            )}
        </Box>
    );
};

Question.propTypes = {
    question: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.string),
    correctAnswer: PropTypes.string,
    onNext: PropTypes.func,
    slideTime: PropTypes.number,
};

export default Question;
