import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import {
    Btn,
    Type,
    Box,
    FieldText,
    Spinner,
    PanelSteps,
    Grid,
    ValidationForm,
} from '@saladbob/sassafras';

const Unsub = () => {
    const [step, setStep] = useState(0);
    const [msg, setMsg] = useState(false);

    const onSubmit = (e) => {
        setStep(1);
        const formData = new FormData(e);

        fetch('/api/unsub/', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === '1') {
                    setTimeout(() => {
                        setStep(2);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setMsg(data.msg);
                        setStep(0);
                    }, 1000);
                }
            });
    };

    return (
        <Box
            align="center"
            bgColor="neutral50"
            minHeight="calc(100vh - 100px)"
            padding="xl"
        >
            <Box maxWidth="640px" margin="auto">
                <PanelSteps step={step}>
                    <ValidationForm onSubmit={onSubmit}>
                        <Type font="h3" tag="h3" margin={[0, 0, 'xs', 0]}>
                            Unsubscribe
                        </Type>
                        {msg && (
                            <Box bgColor="primary" margin={['md', 0]} padding="sm" txtColor="white">
                                <Type font="p" tag="p" align="center">
                                    {msg}
                                </Type>
                            </Box>
                        )}
                        <Grid columns="3fr 1fr" columnGap="md">
                            <FieldText
                                name="e"
                                rules={{ email: true, required: true }}
                                placeholder="Your Email"
                                size="xl"
                            />
                            <Btn color="black" size="xl" full>
                                Unsubscribe
                            </Btn>
                        </Grid>
                    </ValidationForm>
                    <Box minHeight="260px" align="center" verticalAlign="center">
                        <Spinner color="primary" />
                    </Box>
                    <Box minHeight="260px" align="center" verticalAlign="center">
                        <Type font="h3" tag="h3">
                            You have been unsubscribed.
                        </Type>
                    </Box>
                </PanelSteps>
            </Box>
        </Box>
    );
};

Unsub.propTypes = {
    // bgImage: PropTypes.string,
};

export default Unsub;
