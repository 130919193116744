import React from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    H1,
    H2,
    FieldRadio,
} from '@saladbob/sassafras';

function SubmitQuestion({ question, onSubmit, index }) {
    const submit = (e) => {
        onSubmit(e, index);
    };

    return (
        <Box>
            {typeof question.title === 'string'
                && (
                    <H1
                        className="color-text-secondary-500"
                        font="h2"
                        align="center"
                        weight="bold"
                    >
                        {question.title}
                    </H1>
                )}
            {typeof question.subtitle === 'string'
                && (
                    <H1
                        font="leader"
                        align="center"
                        mb="sm"
                    >
                        {question.subtitle}
                    </H1>
                )}
            <H2
                font="leader"
                align="center"
                weight="bold"
                mb="md"
            >
                {question.q}
            </H2>
            <Box maxWidth="400px" margin="auto">
                <FieldRadio
                    uncheckedColor="black"
                    checkedColor="success"
                    columns="1fr 1fr"
                    options={question.options}
                    name={question.name}
                    onChange={submit}
                />
                {typeof question.img === 'string' && (<img src={question.img} alt={question.q} />)}
            </Box>
        </Box>
    );
}

SubmitQuestion.propTypes = {
    question: PropTypes.object,
    onSubmit: PropTypes.func,
    index: PropTypes.number,
};

export default SubmitQuestion;
