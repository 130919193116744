import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Type } from '@saladbob/sassafras';
import ProgressDots from '../components/ProgressDots/ProgressDots';

import freeLoader from '../utils/freeLoader';

const LoadingA = ({ time }) => {
    const [progress, setTime] = useState({});

    useEffect(() => {
        freeLoader(
            time,
            (pgs) => setTime(pgs),
            true,
            () => setTime(0),
        );
    }, []);

    return (
        <Box
            minHeight="calc(100vh - 88px)"
            bgColor="primary900"
            txtColor="white"
            verticalAlign="center"
            align="center"
        >
            <Type font="jumbo">
                Processing...
            </Type>
            <Type font="h1" mb="xxl">
                We&apos;re finding loan offers for you
            </Type>
            <Type font="loading" mb="xxl">
                {progress.hrPercent}
                %
            </Type>
            <Box mb="xxl">
                <ProgressDots numDots={50} percent={progress.percent} />
            </Box>
            <Type font="p" tag="p" align="center">
                <strong>Do not cancel, reload, or leave this page until the process has been completed.</strong>
                <br />
                If you do not wait for this process to complete your loan may not be funded.
                Please be patient, as this may take a few moments.
            </Type>
        </Box>
    );
};

LoadingA.propTypes = {
    time: PropTypes.number,
};

LoadingA.defaultProps = {
    time: window.loadingTime || 120,
};

export default LoadingA;
