/* eslint-disable max-len */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    ThemeContext,
} from '@saladbob/sassafras';

const Lock = ({ color }) => {
    const { colors } = useContext(ThemeContext);
    const styles = useMemo(() => ({
        fill: colors[color] || colors.primary,
        strokeWidth: '0px',
    }), [color, colors.primary]);
    return (
        <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 939.43 1153.41">
            <g>
                <path style={styles} d="M469.72,1153.41l-18.44-10.72c-79.31-46.1-150.17-93.15-210.62-139.84-74.49-57.55-135.79-132.66-177.28-217.2C21.92,701.14,0,606.71,0,512.54V178.6l30.79-5.01C229.63,141.22,448.82,12.38,451.01,11.08l18.71-11.08,18.71,11.08c2.18,1.29,221.38,130.13,420.21,162.5l30.79,5.01v333.94c0,94.16-21.92,188.6-63.38,273.11-41.49,84.55-102.79,159.66-177.28,217.2-60.45,46.7-131.31,93.75-210.62,139.84l-18.44,10.72ZM73.37,240.35v272.19c0,168.03,79.31,329.62,212.15,432.25,53.22,41.12,115.11,82.66,184.2,123.65,69.09-40.99,130.97-82.53,184.2-123.65,132.84-102.62,212.15-264.21,212.15-432.25V240.35c-170.23-34.68-340.5-124.29-396.34-155.46-55.84,31.17-226.12,120.78-396.34,155.46Z" />
                <polygon style={styles} points="650.77 411.67 412.31 650.12 288.26 526.08 234.23 580.1 412.25 758.12 412.31 758.06 412.37 758.12 704.8 465.69 650.77 411.67" />
            </g>
        </svg>
    );
};

Lock.propTypes = {
    color: PropTypes.string,
};

export default Lock;
