import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Btn,
    H3,
    ThemeContext,
} from '@saladbob/sassafras';

import { ThumbsUp, ThumbsDown } from 'react-feather';

import Badge from '../svg/Badge';

const Fact = ({
    question,
    correctAnswer,
    onNext,
    slideTime,
}) => {
    const { colors } = useContext(ThemeContext);
    const [answer, setAnswer] = useState();

    const onClick = (a) => {
        if (typeof onNext === 'function') {
            onNext(a === correctAnswer);
        }
    };

    useEffect(() => {
        if (typeof answer !== 'undefined') {
            setTimeout(() => { onClick(answer); }, slideTime || 1800);
        }
    }, [answer]);

    return (
        <Box bgColor="secondary">
            <div className="fact__inner">
                <div className="fact-badge">
                    <Badge color={colors.primary} />
                    <span className="fact__badge__text">
                        <span>FACT</span>
                        <span>OR</span>
                        <span>NOT</span>
                    </span>
                </div>
                <H3 align="center">{question}</H3>
                {answer && (
                    <div className="fact__answer">
                        <span className="fact__answer__text">
                            {answer === correctAnswer ? 'Correct!' : 'Wrong!'}
                            {correctAnswer ? ' This is True' : ' This is False'}
                        </span>
                    </div>
                )}
                {typeof answer === 'undefined' && (
                    <>
                        <Btn
                            iconLeft={<ThumbsUp />}
                            onClick={() => setAnswer(true)}
                        >
                            TRUE
                        </Btn>
                        <Btn
                            iconLeft={<ThumbsDown />}
                            onClick={() => setAnswer(false)}
                        >
                            FALSE
                        </Btn>
                    </>
                )}
            </div>
        </Box>
    );
};

Fact.propTypes = {
    question: PropTypes.string,
    correctAnswer: PropTypes.string,
    onNext: PropTypes.func,
    slideTime: PropTypes.number,
};

export default Fact;
