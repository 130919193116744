/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    FieldPhone,
} from '@saladbob/sassafras';

const PhoneField = ({ font, ...props }) => (
    <FieldPhone
        mb="xl"
        font={font}
        {...props}
    />
);

PhoneField.propTypes = {
    name: PropTypes.string,
};

export default PhoneField;
