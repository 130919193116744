/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities  */

import React from 'react';
import PropTypes from 'prop-types';

import ContentPage from '../components/ContentPage';

const Privacy = ({ brandName, email, address }) => (
    <ContentPage title="Privacy Policy" subtitle={(<i>EFFECTIVE DATE:  JANUARY 1, 2022.</i>)}>
        <p>
            {brandName}
            {' '}
            ("Company" "we" or "our") is committed to advising you of the right to your privacy and strives to provide a safe and secure user experience. This Privacy Policy explains how we collect, store and use personal information provided by you on our Website. By accessing and using our Website, you explicitly accept, without limitation or qualification, the collection, use and transfer of the personal information provided by you in the manner described in this Privacy Policy. Please read this Policy carefully, as it affects your rights and our liabilities under the law. This policy replaces any privacy policy previously delivered to you or appearing on this website. If you disagree with the way we collect and process personal information, please do not use our Website.
        </p>
        <h4>1. Information We Collect.</h4>
        <p>1.1. We collect general types of information about you when you visit and use our Website; examples include personal information you affirmatively provide on our forms, demographic information, behavioral information, and indirect information. Sometimes we collect combinations of these types of information:</p>
        <p>1.1.1. Personal Information. Is information that can be used to identify you or any other individual to whom the information may relate. This is information which you are prompted to provide to us. Such information may include your name, address, telephone number(s), mobile numbers and email address, date of birth, social security number, driver's license, bank account(s), employment status or self-employment, or other unique information about you which you provide to us during the registration process, or through the course of communicating with us about the products and services provided on our Website. We may collect this same information about all other persons when you provide it to us.</p>
        <p>1.1.2. Demographic Information. Is information that may or may not be unique to you in the sense that it refers to selected population characteristics. Such information may include, but is not limited to, zip code, mobile phone carrier, age, gender, salary range, education, marital status, occupation, military service, industry of employment, personal and online interests. When we can identify you personally through the use of this information, it becomes Personal Information.</p>
        <p>1.1.3. Behavioral Information. Is information which is automatically collected when you use our Website. This information includes the areas of our Website that you visit, what services you access or ordered, information about your computer hardware and software, including your IP address, geographic location, browser preference, operating system type, domain names, times that you access the internet, and other websites you have visited. When we can identify you personally through the use of this information, it becomes Personal Information.</p>
        <p>1.1.4. Third-Party Information. Is information about you that we acquire from a third-party which may include personal, demographic, behavioral and indirect information. This collection may also include, but not limited to, first party cookies, third-party cookies, anonymous cookies, persistent identifiers, email opt-in records and search engine keywords. We have no access or control over these cookies and other tracking devices used by third-parties including advertisers and networks, internet service providers, data analytics providers or social networks and platforms. We have no responsibility or liability for the policies and practices of these parties. When we can identify you personally through the use of this information, it becomes Personal Information.</p>
        <p>1.1.5. Web Technology Information. Is information we automatically collect from you when you visit our Website. This includes internet protocol ("IP") addresses, browser type, internet service provider ("ISP"), referring/exit pages, operating system, date/time stamp, and/or clickstream data. This information is collected from the use of cookies, Web beacons or JavaScript.</p>
        <p>1.1.6. No Information Collected from Children. We will never knowingly collect any Personal Information from children under the age of 16. If we obtain actual knowledge that we have collected Personal Information about a child under the age of 16, that information will be immediately deleted from its database. Because it does not collect such information, we have no such information to use or to disclose to third-parties. Our site and its services are not intended for use by consumers under the age of 18.</p>
        <p>1.1.7. No Sensitive Health Information is Collected. We do not collect information about past, present or potential future medical conditions or treatments. Nor do we collect any biometric information.</p>
        <p>1.1.8. Bank Account Information. We may, in certain instances, collect bank account information and related information, when you place a request on our Website. When bank account information is submitted to us, such information is encrypted and is protected with SSL encryption software. We will use the bank account information for purposes of processing and completing your requests for services. As such, information will be disclosed to third-parties as necessary to complete the requested purchase transaction.</p>
        <h4>2. Collection of Information Through Cookies, Web Beacons, and JavaScript.</h4>
        <p>2.1. Generally, we as well as third-party vendors, networks, ISP(s) and supporting advertisers use technologies such as cookies, web beacons, and java scripts. These technologies collect internet protocol ("IP") addresses, browser type, internet service provider ("ISP"), referring/exit pages, operating system, date/time stamp, and/or clickstream data. This information is used to analyze trends, administer our Website, track users' movements through our Website and gather demographic information about our user base as a whole. We may receive reports based on these technologies on an individual or aggregated basis. California residents have the right to know if we respond to do not track signals or cookies. We do not respond to such signals or cookies.</p>
        <p>2.1.1. Cookies. "Cookies" are a feature in your browser software. If enabled, cookies store small amounts of data on your computer about actions you take on the pages of our Website. Cookies assist us in tracking which of our features you visit most often, and what content you viewed on past visits. When you visit this website again, cookies allow us to remember your settings and may be used for authentication. We may use cookies to keep track of the number of return visits, accumulate and aggregate statistical information generally pertaining to our Website, and deliver specific content to you based on your past viewing history. You can disable cookies, although our Website may not function properly for you. Your browser preferences can be modified to accept or reject all cookies or request a notification when a cookie is set. You may read more about cookies at http://cookiecentral.com/faq/</p>
        <p>2.1.2. Third-Party Cookies. We allow third-party vendors and advertisers to set their own cookies on and through our Website. We have no control over the practices of those third-parties and are not responsible for their technology or tracking. We encourage you to review the policies of such persons or entities on their websites. We use various ad services provided by Google that connects the activity on our Website with their advertising network and they may use cookies that collects information regarding certain patterns in your browsing history. To opt-out of this tracking, please see https://support.google.com/ads/answer/2662922?hl=en. We also participate in all of Google's Analytics Advertising. This includes: (i) Remarketing with Google Analytics; (ii) Google Display Network Impression Reporting; (iii) Google controlled Campaign Manager integration; and (iv) Google Analytics Demographics and Interest Reporting. Google uses cookies to track activity performed by Google Analytics and its AdWords or DoubleClick cookie. To opt-out please see https://tools.google.com/dlpage/gaoptout/. We also use Google Analytics which is an analysis service. Google utilizes the data collected through its cookies to track and examine the use of this site, to prepare reports on its activities, and to share them with other Google services. You may opt-out of the Google Analytics service using Google's Browser Add-on here https://tools.google.com/dlpage/gaoptout/.</p>
        <p>2.1.3. Social Media Cookies. Our Website includes third-party social media features. These features may collect your IP address, which page you are visiting on our Website, and may set a cookie to enable the feature to function properly. Social media features are either hosted by a third-party or hosted directly on our Website. Your interactions with these features are governed by the Privacy Policy of the company providing it.</p>
        <p>2.2. Web Beacons. We use electronic images known as Web Beacons (sometimes called single-pixel gifs, clear gifs or action tags) which allow us to collect information about your visit to our Website, measure and improve the effectiveness of advertisements and track delivery of advertising. Web Beacons collect only a limited set of information including a cookie number, time and date of page view, as well as a description of the page on which the Web Beacon resides. We may also use Web beacons in email messages sent to you. This allows us to determine if you opened or acted upon the email message. Because Web beacons are the same as any other content request, you cannot opt-out or refuse them. However, they can be rendered ineffective by either opting-out of cookies or changing the cookie setup in your browser.</p>
        <p>2.3. JavaScript. We may also use JavaScript. JavaScript is a computer language that enhances the functionality of websites, particularly with respect to pictures. We use it to analyze and improve our Website's functions. You may deactivate JavaScript through your browser settings or activate it the same way. If you disable JavaScript, you will not be able to use some of the functions of our Website.</p>
        <h4>3. How We Use Information Collected.</h4>
        <p>3.1. Providing Services and Products. We use the information we gather on our Website to provide you with the services and or products you have requested. This may include passing your information on to a third-party to provide such services or products, such as lenders and financial service providers who may secure a loan for you. Although our contractual arrangement limits how this party can use your information, we do not control the privacy practices of third-parties. If you have any questions or wish to remove your information from the third-party databases, you will need to contact that party directly.</p>
        <p>3.2. Improving Our Website. We use the information we gather to respond to any inquiries you make, operate and improve the functionality of our Website, and deliver the products and services advertised on our Website. We use the same for testing, research, analysis and product or marketing development. Our services include the display of personalized products, content, and advertising, relating to your experience and interests. This includes maintaining and improving the safety, security and integrity of our Website, databases and technology assets.</p>
        <p>3.3. Promotions and Communications with You. We also use the information we gather to promote our and third-party services and products to you that might be of interest to you. This may include, but is not limited to, communicating with you by email, telephone, and/or SMS via mobile devices about products or services that may be of interest to you ("marketing messages"), either from our site, our network of lenders, marketing partners, and affiliates. We also use the information we have gathered to respond to your inquiries, concerns or requests. If your request is made through a third-party, we may use the information to respond to their requests.</p>
        <p>3.4. Telephone Consumer Protection Act and SMS. You agree that by affirmatively submitting your information and telephone number on this website, you are providing express written consent and agree to receive telemarketing calls, auto-dialed calls, pre-recorded calls, text messages ("SMS Messages"), from Cell Loans, this site, and our designated third-party advertisers and service providers. Your consent is not required to submit a request or utilize our services. Our website strives to remain in full compliance with the provisions of the Telephone Consumer Protection Act (TCPA) and therefore, we do not call, send SMS messages, or send voicemails to any consumer without the consumer's prior express consent. You understand that standard data and messaging rates apply to all SMS correspondence and that all charges are billed by and payable to your mobile service provider. You further represent that you are the owner/authorized user of the wireless device on which messages will be received. SMS message services are provided on an AS IS basis and we do not guarantee that your use of the SMS Service will be secure or free of error. You may opt out of the SMS Service at any time by replying "STOP" or "OPT-OUT" to the SMS text message you have received or clicking the Opt-Out link on the text message you have received. Notwithstanding that your mobile telephone number may be listed on Federal or State Do-Not-Call registry, we retain the right to contact you via SMS Messages once you have provided express written consent.</p>
        <p>3.5 LoanSign SMS Alerts &amp; Reminders Terms and Conditions. By providing your consent to receive telemarking calls, auto-dialed calls, pre-recorded calls, and text messages, you agree to receive recurring monthly messages with additional offers. To Opt-Out at any time, text the word STOP to the short code provided or click the Opt-out link in the message. For help, text the word HELP. Message and Data Rates May Apply. For additional support: info@cellloans.com. Based on program terms, you may receive a one-time text message or recurring monthly messages (up to 5 messages per week). This service is available on all Supported U.S. Carriers. Messages may be delayed or undelivered for various factors. Client, carriers (including, but not limited to, T-Mobile) and any service providers utilized by Client to send messages are not liable for delayed or undelivered messages.</p>
        <h4>4. Disclosure of Information to Others.</h4>
        <p>4.1. We will share your Personal Information with third-parties and service providers as described in this Privacy Policy.</p>
        <p>4.1.1. Product and Service Delivery. We share all categories of information set out in this policy with third-parties who help us in the delivery of the products and services you have requested. This may include sharing information with third party lenders and financial service providers that may secure a loan for you.</p>
        <p>4.1.2. Website Functionality. We share all categories of information set out in this policy with third-parties we employ to perform technical functions on our behalf. Examples include third-parties who host our Website, analyze our data, provide marketing assistance, process credit card payments, and provide customer service as set forth above.</p>
        <p>4.1.3. Third-Party Products and Services. We share all categories of information set out in this policy with third parties including affiliated entities, lenders, service providers, and data aggregators with your information with third parties who will provide you with their opportunities, products or services. Again, this includes your personal and non-personal information, and includes your interests and preferences, so they may determine whether you might be interested in their products or services. Lenders and financial service providers may, in order to review your creditworthiness, use your information to obtain credit checks or consumer reports through alternative providers, which may but not typically include Experian, Equifax, or Trans Union. By affirmatively submitting your information on this site, you acknowledge, agree, and authorize such lenders to obtain consumer reports or related information about you from one or more consumer reporting agencies to evaluate your creditworthiness for a loan or confirm your identity for fraud prevention.</p>
        <p>4.1.4. Anonymized information. We share aggregated anonymous information about you, combined with other persons using our Website with third-parties, so that they can understand the kinds of visitors that come to our Website, and how those visitors use our Website and promotion of our marketing. This includes demographic information and behavioral information.</p>
        <p>4.1.5. Legal Process. We disclose your information if legally required to do so, or at our discretion, pursuant to a request from a governmental entity, or if we believe in good faith that such action is necessary to: (a) conform to legal requirements or comply with legal process; (b) protect our rights or property, or our affiliated companies; (c) prevent a crime or protect national security; or (d) protect the personal safety of users or the public.</p>
        <p>4.1.6. Acquisition or Merger. We may disclose and transfer your information to a third-party who acquires any or all of our business, whether such acquisition is by way of merger, consolidation or purchase of all or a substantial portion of our assets. In the event we become the subject of an insolvency proceeding, whether voluntary or involuntary, we or our liquidator, administrator, receiver or administrative receiver may sell, license or otherwise dispose of, such information in a transaction approved by the court.</p>
        <h4>5. Third-Party Collection and Use of Information.</h4>
        <p>5.1. Third-parties collect and use information about you on or through our Website in the following way:</p>
        <p>5.1.1. Advertisers. Advertising agencies, advertising networks, and other companies who place ads on our Website, may use their own cookies, Web beacons, and other technology, to collect information about you. We do not control the use of such technology and have no responsibility for the use of such technology to gather information about you.</p>
        <p>5.1.2. Hyperlinks. Our Website and email messages sometimes contain hypertext links to websites owned by third-parties. We are not responsible for the privacy practices or the content of such other websites. These links are provided for your convenience and reference only. We do not operate or control any information, software, products or services, available on these third-party websites. The inclusion of a link on our Website does not imply any endorsement of the services, products or website, or its sponsoring organization.</p>
        <p>5.1.3. Analytics. As described above, we use third-parties to monitor, analyze and report on the traffic to, from and within our Website and email messages.</p>
        <h4>6. Security.</h4>
        <p>6.1. We endeavor to safeguard and protect your information. When you submit information on our Website, such information is protected both online and offline. We have security measures in place to protect against the loss, misuse, and alteration of personal information under our control. The servers in which we store your information are kept in a secure physical environment. The servers have industry standard firewalls. Access to such servers is password protected and access by our employees is limited. Currently, we use Secure Socket Layer software ("SSL") to protect data and to secure any transactions. SSL encrypts information including credit card number(s), and names and addresses, as they are transmitted over the Internet. Please be advised that, although we take commercially reasonable technological precautions to protect your data, no data transmission over the Internet can be guaranteed to be 100% secure; therefore, we cannot and do not warrant that your information will be absolutely secure. Any transmission of data at or through our Website is at your own risk. However, access to your information is strictly limited and not accessible to the public.</p>
        <h4>7. Changes to Privacy Policy.</h4>
        <p>7.1. We reserve the right to make material changes to the substance of this Privacy Policy. We will post those changes through a prominent notice on the website, so that you will always know what information we gather, how we might use that information, and to whom we will disclose it.</p>
        <h4>8. Opt-In / Opt-Out and Unsubscribe.</h4>
        <p>
            This website, our affiliates, and our network of lenders and financial service providers may contact you to promote and offer products and services that may be of interest to you. Consumers who requested to be contacted through our website's online forms or through any Opt-in process of our business partners and/or affiliates may be contacted via e-mail or telephone. If you do not wish to receive these emails, telephone solicitations, or other forms of contact from our company or affiliates partners, you may unsubscribe or opt-out at any time by visiting our
            {' '}
            <a href="/unsub/">Unsubscribe Page</a>
            .
        </p>
        <h4>9. California Residents Rights.</h4>
        <p>
            9.1 The California Consumer Protection Act ("CCPA") provides California residents with specific rights regarding their personal information. Please click
            {' '}
            <a href="/ca-privacy/">here</a>
            {' '}
            for a description of those rights and how to exercise those rights, including the do not sell my personal information right afforded you.
        </p>
        <p>
            9.2 In addition, California's Shine the Light Law provides California residents with the right to receive, once a year, information about third-parties by category with whom we have shared information about you or your family for their marketing purposes during the previous calendar year, and a description of the categories of personal information shared. California residents also have the right to know if we respond to do not track signals or cookies. We do not respond to such signals or cookies. As stated in this Policy, you have agreed to allow us to share information with third parties for their direct marketing purposes until you remove your information; and thus, you have agreed to this disclosure. California customers may request further information about our compliance with this law by sending us an email at
            {' '}
            <a href={`mailto:${email}`}>{email}</a>
            . Please note that we are only required to respond to one request per customer each year, and we are not required to respond to requests made by means other than through this email address.
        </p>
        <hr />
        <h2>CALIFORNIA STATE PRIVACY NOTICE</h2>
        <p><i>Last Revised: January 1, 2022</i></p>
        <h3>PRIVACY STATEMENT FOR CALIFORNIA CONSUMERS</h3>
        <p>
            This PRIVACY NOTICE IS FOR CALIFORNIA RESIDENTS ONLY and supplements the information contained in the posted
            {' '}
            <a href="/privacy/"> privacy policy</a>
            {' '}
            of
            {' '}
            {brandName}
            {' '}
            ("we," "us," or "our") and applies solely to California Residents ("Consumers" or "you") who visit, use, and interact with our services, products and Website. We adopt this notice to comply with the California Consumer Privacy Act of 2018 ("CCPA") and other California privacy laws. Any terms defined in the CCPA have the same meaning when used in this notice. The Purpose of this Notice is to provide you with a comprehensive description of our practices regarding the collection, use, disclosure and Sale of your Personal Information. It further provides you with information as to how to contact us to review, correct or delete your information. You will find a submit form at the bottom of this section to contact us with your requests.
        </p>
        <h3>California Resident Privacy Rights.</h3>
        <h4>(1) Right to Know About Personal Information Collected, Disclosed, or Sold.</h4>
        <p>a. You have the right to request that we advise you what Personal Information we collect, use, disclose, and sell that belongs to you. Personal Information does not include: (i) Publicly available information from government records; (ii) De-identified or aggregated consumer information; (iii) Information excluded from the CCPA's scope, like health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 ("HIPAA"); (iv) Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act ("FRCA"), the Gramm-Leach-Bliley Act ("GLBA"), California Financial Information Privacy Act ("FIPA"), or the Driver's Privacy Protection Act of 1994 ("DPPA").</p>
        <p>
            b. You may submit a verifiable consumer request to obtain the specific Personal Information we have collected about you either through our California Resident Portal form or you may email us at
            {' '}
            <a href={`mailto:${email}`}>{email}</a>
            .
        </p>
        <p>c. Upon receiving your information, we are required to verify that you are the same consumer with whom we have the information in our systems. To verify your identify, we will require that you fill out the portal form in its entirety which includes your name, address, email address and may include the last four digits of your social security number, your bank account number and other Personal Information we have collected.</p>
        <p>d. As set forth in our primary Privacy Policy, we collect several types of information about you when you visit and use our Website; examples include Personal Information, demographic information, behavioral information, and indirect information. Sometimes we collect combinations of these types of information.</p>
        <p>e. Personal Information has been collected from the following categories of sources:</p>
        <p>i. Information collected directly and indirectly from your activity on our website and your interactions or communications with us. This includes your Personal Information provided by you in connection with obtaining a certain product or service offered by us or one our network providers or advertisers.</p>
        <p>ii. Information you provide us when you enter promotions or sweepstakes offered by us or our clients including networks and advertisers.</p>
        <p>iii. Information you provide us through telephone and web-based surveys, customer service correspondence, and general feedback.</p>
        <p>iv. Information we received directly or indirectly from our clients or their agents in the course of providing services to you and others.</p>
        <p>v. Analytics companies who provide demographic information such as gender, zip code, or any information that is not tied to your Personal Information. In addition, we may receive information about you from other online or offline sources, including third-parties from whom we validate consumer self-reported information, and verify information we already have about you. This helps us to update, expand and analyze our records and provide products and services that may be of interest to you.</p>
        <p>vi. We also collect information provided by Service Providers and other third-parties as set out in our main Privacy Policy, our advertisers, ad networks and third-party technology companies.</p>
        <p>f. We have disclosed, shared and/or received compensation for our disclosure of Personal Information for the following commercial purposes in the preceding 12 months:</p>
        <p>i. To obtain the services or products you have registered for on our Website.</p>
        <p>ii. To allow a third-party to market their products or services to you.</p>
        <p>iii. To provide you with information, products or services that you request from us or resolve consumer concerns or request to opt-out of marketing. To allow a third-party to market their products or services to you.</p>
        <p>iv. To provide you with notices concerning our products or services, or events or news, that may be of interest to you.</p>
        <p>v. To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections.</p>
        <p>vi. To improve our website, products and services, and present its contents to you including testing, research, analysis and product development.</p>
        <p>vii. To personalize your Website experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Website, third-party sites, and via email or text message (with your consent, where required by law).</p>
        <p>viii. To maintain the safety, security, and integrity of our Website, platforms as well as products and services, databases and other technology assets, and business.</p>
        <p>ix. To respond to government or law enforcement requests and as required by applicable law, court order, or governmental regulations.</p>
        <p>x. To evaluate or conduct a merger, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our business or assets.</p>
        <p>xi. As described to you when collecting your Personal Information or as otherwise set forth in the CCPA.</p>
        <p>g. We have disclosed Personal Information to the following categories of third-parties:</p>
        <p>i. Our affiliated entities.</p>
        <p>ii. Our Service Providers.</p>
        <p>iii. Data aggregators.</p>
        <p>iv. Advertisers.</p>
        <p>v. Ad Networks.</p>
        <p>vi. Third-parties to whom you or your agents authorize us to disclose your Personal Information in connection with products or services we and/or third-parties provide to you.</p>
        <p>h. We do not collect, distribute, share or sell the Personal Information of anyone under the age of 16.</p>
        <h4>(2) Right to Request Deletion of Personal Information.</h4>
        <p>a. You have a right to request the deletion of your Personal Information if we have collected or maintained it in the past 12 months.</p>
        <p>
            b. You may submit a verifiable consumer request to delete your information using our California Consumer Portal and fully fill out the request form or contact us by email at
            {' '}
            <a href={`mailto:${email}`}>{email}</a>
            .
        </p>
        <p>c. Once we receive and confirm your verifiable consumer request, we will disclose to you the following:</p>
        <p>i. The categories of Personal Information we collected about you.</p>
        <p>ii. The categories of sources for the Personal Information we collected about you.</p>
        <p>iii. Our business or commercial purpose for collecting or selling that Personal Information.</p>
        <p>iv. The categories of third-parties with whom we shared that Personal Information.</p>
        <h4>(3) Right to Opt-Out of the Sale of Personal Information.</h4>
        <p>You have the right to opt-out of any sale of your personal information by a business. If you are requesting a product or service, this is an exemption from this right, and we will process your request. However, you have the right to cease all further sharing of information.</p>
        <h4>(4) Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights.</h4>
        <p>You have a right not to receive discriminatory treatment by us for the exercise of these privacy rights conferred by the CCPA. As such, we will not deny you goods or services, charge you different prices or rates for goods or services, or deny you discounts or other benefits; nor will we provide you a different level or quality of goods or services or recommend a different level or quality of goods or services.</p>
        <h4>(5) Authorized Agent.</h4>
        <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your Personal Information.</p>
        <h4>(6) Contact Us.</h4>
        <p>
            Contact us for more information or with any questions either through our California Consumer Portal or by email at
            {' '}
            <a href={`mailto:${email}`}>{email}</a>
            .
        </p>
        <h4>(7) Limitations on Requests.</h4>
        <p>You may only make a verifiable consumer request for access twice within a twelve (12) month period. The verifiable consumer request must: (a) Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or your authorized agent; (b) Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to you; (c) We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the Personal Information relates to you; (d) Making a verifiable consumer request does not require you to create an account with us. We will only use Personal Information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
        <h4>(8) Response Time and Response Format.</h4>
        <p>We make every effort to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. We we will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the twelve (12) month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons why we cannot comply with a request, if applicable. We will provide our response in a manner that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance to you.</p>
        <h4>(9) Changes to California Resident Privacy Notice.</h4>
        <p>We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will enforce the terms of the new policy going forward. You will receive no other notice than an updated posting on this page. Please check back each time you enter our Website.</p>
        <h4>(10) Contact Information.</h4>
        <p>If you have any questions or comments about this Privacy Statement, please do not hesitate to contact us at:</p>
        <p>
            Website:
            {' '}
            {brandName}
        </p>
        <p>
            Email:
            {' '}
            <a href={`mailto:${email}`}>{email}</a>
            .
        </p>
        <p>
            Address:
            {' '}
            {address.street}
            <br />
            {address.city}
            ,
            {' '}
            {address.state}
            {' '}
            {address.zip}
        </p>
    </ContentPage>
);

Privacy.propTypes = {
    brandName: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.object,
};

export default Privacy;
