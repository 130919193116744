import React from 'react';
import { Check } from 'react-feather';
import {
    Box, P,
} from '@saladbob/sassafras';

const BlurbForm = () => (
    <Box margin={[0, 0, 'xl', 0]}>
        <Box
            rounded="sm"
            padding="md"
            depth="xxl"
            maxWidth="320px"
            margin={[0, 'auto', 'lg', 'auto']}
        >
            <Box
                bdColor="neutral100"
                bdWidth="3px"
                rounded="xs"
                height="48px"
                align="center"
                padding={[0, 0, 0, 'md']}
                margin={[0, 'auto', 'md', 'auto']}
            >
                <P color="neutral100" mb="0">First Name</P>
            </Box>
            <Box
                bdColor="neutral100"
                bdWidth="3px"
                rounded="xs"
                height="48px"
                align="center"
                padding={[0, 0, 0, 'md']}
                margin={[0, 0, 'md', 0]}
            >
                <P color="neutral100" mb="0">Last Name</P>
            </Box>
            <Box
                bgColor="secondary"
                rounded="xs"
                height="48px"
                align="center"
                justify="center"
                txtColor="white"
            >
                <Check />
            </Box>
        </Box>
    </Box>
);

export default BlurbForm;
