import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '@saladbob/sassafras';
import { createUseStyles } from 'react-jss';
import styles from './ProgressDots.module.css';

const progressDotsStyles = createUseStyles({
    Active: {
        '&::after': {
            backgroundColor: ({ theme, color }) => theme.colors[color] || color,
        },
    },
    Waiting: {
        backgroundColor: ({ theme, color }) => theme.colors[color] || color,
    },
});

const getDot = (percent, numDots) => {
    const dotPercent = 1 / numDots;
    return Math.floor(percent / dotPercent);
};

const ProgressDots = ({ numDots, percent }) => {
    const theme = useContext(ThemeContext);
    const dots = [];
    const dot = getDot(percent, numDots);

    const classes = progressDotsStyles({ theme, color: 'primary' });

    const activeDot = useMemo(() => {
        if (dot >= numDots) {
            return numDots - 1;
        }
        return dot;
    }, [dot, numDots]);

    for (let i = 0; i < numDots; i += 1) {
        dots.push((
            <div
                key={i}
                className={[
                    styles.dot,
                    i < activeDot ? `${styles.active} ${classes.Active}` : '',
                    i === activeDot ? `${styles.waiting} ${classes.Waiting}` : '',
                ].join(' ')}
            />
        ));
    }

    return (
        <div className={styles.dots}>
            {dots}
        </div>
    );
};

ProgressDots.propTypes = {
    numDots: PropTypes.number,
    percent: PropTypes.number,
};

export default ProgressDots;
