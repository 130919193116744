import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ArrowRightCircle } from 'react-feather';
import {
    Box,
    H1,
    P,
    Type,
    Btn,
    Grid,
    ThemeContext,
} from '@saladbob/sassafras';

const headingFont = {
    phone: 'h3',
    laptop: 'h1',
};

const padding = {
    phone: 'md',
    desktop: 'xxl',
};

const subheadingFont = {
    phone: 'p',
    laptop: 'leader',
};

const Pop = ({
    publicPath,
    imgPath,
    brandPath,
    popLinks,
}) => {
    const { mediaQueries: { desktop } } = useContext(ThemeContext);

    return (
        <Box
            minHeight="calc(100vh - 140px)"
            bgColor="primary900"
            txtColor="white"
            bgImage={`${publicPath}${imgPath}${brandPath}pop-bg.jpg`}
        >
            <Box height="auto" padding={padding} justify="center">
                <div>
                    <H1
                        font={headingFont}
                        weight="normal"
                        align="center"
                    >
                        For Instant Loan Request Information
                    </H1>
                    <P font={subheadingFont} align="center">
                        Compare the options below, you can
                        {' '}
                        <strong>ACCEPT MULTIPLE OPTIONS</strong>
                        {' '}
                        if your needs vary
                    </P>

                    <Grid columns="max-content 1fr" gridGap="md">
                        {popLinks.map(({ href, label }, index) => (
                            <>
                                <Box align="center">
                                    <Type font="leader" mr="lg">
                                        Option
                                        {' '}
                                        #
                                        {index + 1}
                                    </Type>
                                </Box>
                                <Btn
                                    tag="a"
                                    href={href}
                                    key={href}
                                    target="_blank"
                                    rounded="xxl"
                                    font="leader"
                                    iconLeft={<ArrowRightCircle size={desktop ? 32 : 16} />}
                                >
                                    {label}
                                </Btn>
                            </>
                        ))}
                    </Grid>
                </div>
            </Box>
        </Box>
    );
};

Pop.propTypes = {
    publicPath: PropTypes.string,
    imgPath: PropTypes.string,
    brandPath: PropTypes.string,
    popLinks: PropTypes.arrayOf(PropTypes.string),
};

Pop.defaultProps = {
    publicPath: window.publicPath,
    imgPath: window.imgPath,
    brandPath: window.brandPath,
    popLinks: window.popLinks || [],
};

export default Pop;
