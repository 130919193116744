import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import {
    Btn,
    Type,
    Box,
    FieldText,
    FieldArea,
    FieldSelect,
    Spinner,
    PanelSteps,
    Grid,
    ValidationForm,
} from '@saladbob/sassafras';

import states from '../utils/states';

const recaptchaRef = React.createRef();

const Contact = ({ reCaptchaKey }) => {
    const [step, setStep] = useState(0);
    const [msg, setMsg] = useState(false);

    const onSubmit = (e) => {
        const formData = new FormData(e);

        setStep(1);

        recaptchaRef.current.executeAsync().then((token) => {
            formData.append('recaptcha', token);

            fetch('/api/contact/', {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success === '1') {
                        setTimeout(() => {
                            setStep(2);
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            setMsg(data.msg);
                            setStep(0);
                        }, 1000);
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        setMsg('The was an unexpected error. Please try again.');
                        setStep(0);
                    }, 1000);
                });
        })
            .catch(() => {
                setTimeout(() => {
                    setMsg('The was an unexpected error. Please try again.');
                    setStep(0);
                }, 1000);
            });
    };

    return (
        <Box
            align="center"
            bgColor="darkBlue"
            padding={['xl', 'md']}
            verticalAlign="center"
        >
            <Box maxWidth="640px" margin="auto">
                <PanelSteps step={step}>
                    <ValidationForm onSubmit={onSubmit}>
                        <Type tag="h2" font="jumbo" mb="lg" color="white">
                            Contact Us
                        </Type>
                        {msg && (
                            <Type font="p" tag="p" margin={[0, 0, 'xs', 0]}>
                                {msg}
                            </Type>
                        )}
                        <Grid columns="1fr" rowGap="md">
                            <FieldText
                                name="fn"
                                rules={{ name: true, required: true }}
                                borderColor={false}
                                placeholder="First Name"
                            />
                            <FieldText
                                name="ln"
                                rules={{ name: true, required: true }}
                                borderColor={false}
                                placeholder="Last Name"
                            />
                            <FieldText
                                name="email"
                                rules={{ email: true, required: true }}
                                borderColor={false}
                                placeholder="Your Email"
                            />
                            <FieldSelect
                                name="state"
                                placeholder="State"
                                margin={[0, 0, 'md', 0]}
                                borderColor={false}
                                options={states}
                                rules={{ required: true }}
                            />
                            <FieldArea
                                name="message"
                                rules={{ required: true }}
                                borderColor={false}
                                placeholder="message"
                            />
                            <Btn size="xl" full color="primaryA700" font="h3">
                                Submit
                            </Btn>
                        </Grid>
                    </ValidationForm>
                    <Box minHeight="260px" align="center" verticalAlign="center">
                        <Spinner color="primary" />
                    </Box>
                    <Box minHeight="260px" align="center" verticalAlign="center">
                        <Type font="h3" tag="h3" color="white">
                            Thank you for contacting us. We will get back to you as soon as we can.
                        </Type>
                    </Box>
                </PanelSteps>
            </Box>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={reCaptchaKey}
            />
        </Box>
    );
};

Contact.propTypes = {
    reCaptchaKey: PropTypes.string,
};

export default Contact;
