/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    FieldText,
    Box,
    P,
    HiddenInput,
} from '@saladbob/sassafras';

const AddressField = ({
    name, cityName, stateName, cityValue, stateValue, stateOptions, font, margin, ...props
}) => (
    <>
        <FieldText
            name={name}
            font={font}
            margin={margin}
            {...props}
        />
        { cityValue && stateValue && (
            <Box>
                <P font="control" align="center" padding={[0, 0, 'sm', 0]}>
                    {cityValue}
                    ,
                    {' '}
                    {stateValue}
                </P>
                <HiddenInput name={cityName} value={cityValue} />
                <HiddenInput name={stateName} value={stateValue} />
            </Box>
        )}
        {
            (!cityValue || !stateValue) && (
                <>
                    <FieldText
                        label="City"
                        name={cityName}
                        value={cityValue}
                        font={font}
                        margin={margin}
                        rules={{
                            required: true,
                            minlength: 3,
                        }}
                    />
                    <FieldText
                        label="State"
                        name={stateName}
                        value={stateValue}
                        options={stateOptions}
                        font={font}
                        margin={margin}
                        rules={{
                            required: true,
                        }}
                    />
                </>
            )
        }
    </>
);

AddressField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
};

export default AddressField;
