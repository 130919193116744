import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import {
    Btn,
    Type,
    P,
    Box,
    FieldText,
    FieldSelect,
    FieldCheckbox,
    List,
    Spinner,
    PanelSteps,
    Grid,
    ValidationForm,
} from '@saladbob/sassafras';

import states from '../utils/states';

const recaptchaRef = React.createRef();

const CaPortal = ({ className, reCaptchaKey }) => {
    const [step, setStep] = useState(0);
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState(false);

    const onSubmit = (e) => {
        const formData = new FormData(e);

        setStep(1);

        recaptchaRef.current.executeAsync().then((token) => {
            formData.append('recaptcha', token);

            fetch('/api/contact/ccpa/', {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success === '1') {
                        setTimeout(() => {
                            setStep(2);
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            setMsg(data.msg);
                            setStep(0);
                        }, 1000);
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        setMsg('The was an unexpected error. Please try again.');
                        setStep(0);
                    }, 1000);
                });
        })
            .catch(() => {
                setTimeout(() => {
                    setMsg('The was an unexpected error. Please try again.');
                    setStep(0);
                }, 1000);
            });
    };

    const onError = () => {
        setError(true);
        setTimeout(() => setError(false), 1500);
    };

    return (
        <Box
            className={className}
            align="center"
            bgColor="neutral50"
            minHeight="calc(100vh - 100px)"
            padding="xl"
        >
            <Box maxWidth="960px" margin="auto">
                <Type tag="h1" font="h1" spacing="md">
                    California Consumer Privacy Policy Preference Portal
                </Type>
                <Type tag="h3" font="h3" spacing="md">
                    California Residents Rights:
                </Type>
                <Type tag="p" spacing="md">
                    The California Consumer Privacy Act of 2018 (&quot;CCPA&quot;) provides California Residents with
                    specific rights regarding their Personal Information. In all cases the business is required to
                    verify the resident making the request is the same resident for which the business has collected
                    personal information from.
                </Type>
                <Type tag="p" spacing="md">
                    The options below may be selected:
                </Type>
                <List spacing="md" font="p" listType="decimal">
                    <li>
                        Right to access information. A California resident has the right to request disclosure of the
                        categories and specific pieces of Personal Information the business has collected about the
                        consumer for the previous twelve-month period.
                    </li>
                    <li>
                        Right to delete information. A California resident has the right to request the business delete
                        personal information that the business has collected about the resident.
                    </li>
                    <li>
                        Right to opt-out of the sale of their Personal Information. A consumer has the right to opt-out
                        of the sale of their personal information collected by business.
                    </li>
                </List>
                {msg && (
                    <Type font="p" tag="p" margin={[0, 0, 'md', 0]}>
                        {msg}
                    </Type>
                )}
                <PanelSteps step={step}>
                    <ValidationForm onSubmit={onSubmit} onError={onError}>
                        <Type font="h3" tag="h3" spacing="md">
                            Please Verify Your Identity
                        </Type>
                        <Grid columns="1fr" rowGap="md">
                            <FieldText
                                name="fn"
                                font="p"
                                rules={{ name: true, required: true }}
                                placeholder="First Name"
                                label="First Name"
                            />
                            <FieldText
                                name="ln"
                                font="p"
                                rules={{ name: true, required: true }}
                                placeholder="Last Name"
                                label="Last Name"
                            />
                            <FieldText
                                name="e"
                                font="p"
                                rules={{ email: true, required: true }}
                                placeholder="Your Email"
                                label="Your Email"
                            />
                            <FieldText
                                name="a1"
                                font="p"
                                rules={{ required: true }}
                                placeholder="123 Main St."
                                label="Address"
                            />
                            <FieldText
                                name="ci"
                                font="p"
                                rules={{ required: true }}
                                label="City"
                            />
                            <FieldSelect
                                name="st"
                                font="p"
                                rules={{ required: true }}
                                placeholder="State"
                                label="State"
                                options={states}
                            />
                            <FieldText
                                name="zc"
                                font="p"
                                rules={{ minlength: 5, maxlength: 5, required: true }}
                                placeholder="Zip Code"
                                label="Zip Code"
                            />
                            <FieldText
                                name="ssn3"
                                type="tel"
                                font="p"
                                rules={{ minlength: 4, maxlength: 4, required: true }}
                                placeholder="XXXX"
                                label="Last 4 Digits of Your Social Security Number"
                            />
                            <FieldText
                                name="bank5"
                                type="tel"
                                font="p"
                                rules={{ minlength: 5, maxlength: 5, required: true }}
                                placeholder="XXXXX"
                                label="Last 5 Digits of Your Checking Account Number"
                            />
                            <Type font="h3" tag="h3">
                                Please select one or more actions desired.
                            </Type>
                            <div>
                                <FieldCheckbox
                                    name="ck1"
                                    value="1"
                                    size="xs"
                                    label={(<P>PLEASE DO NOT SELL MY INFORMATION</P>)}
                                />
                                <FieldCheckbox
                                    name="ck2"
                                    value="1"
                                    size="xs"
                                    label={(
                                        <P>
                                            PLEASE TELL ME WHAT PERSONAL INFORMATION
                                            YOU HAVE ON ME AND WITH WHOM YOU HAVE SHARED/SOLD IT
                                        </P>
                                    )}
                                />
                                <FieldCheckbox
                                    name="ck3"
                                    value="1"
                                    size="xs"
                                    label={(<P>PLEASE DELETE MY INFORMATION</P>)}
                                />
                            </div>
                            <div>
                                <Btn color="black" size="xl" shake={error} failed={error}>
                                    Submit
                                </Btn>
                            </div>
                        </Grid>
                    </ValidationForm>
                    <Box minHeight="260px" align="center" verticalAlign="center">
                        <Spinner color="primary" />
                    </Box>
                    <Box minHeight="260px" align="center" verticalAlign="center">
                        <Type font="h3" tag="h3">
                            Thank you for submitting your request.
                        </Type>
                    </Box>
                </PanelSteps>
            </Box>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={reCaptchaKey}
            />
        </Box>
    );
};

CaPortal.propTypes = {
    className: PropTypes.string,
    reCaptchaKey: PropTypes.string,
};

export default CaPortal;
