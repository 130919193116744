import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ArrowRightCircle } from 'react-feather';
import {
    Box,
    Grid,
    H1,
    H5,
    P,
    Type,
    Btn,
    ThemeContext,
    Container,
} from '@saladbob/sassafras';

const Match = ({
    publicPath,
    imgPath,
    brandPath,
    matchLink,
}) => {
    const { mediaQueries: { desktop } } = useContext(ThemeContext);

    return (
        <>
            <Box
                bgColor="primary900"
                txtColor="white"
                verticalAlign="center"
                align="center"
            >
                <Grid columns={{ phone: '1fr', laptop: '1fr 1fr' }}>
                    <Box
                        minHeight="calc(100vh - 140px)"
                        bgImage={`${publicPath}${imgPath}${brandPath}match-bg.jpg`}
                    />
                    <Box height="auto" verticalAlign="center" padding={{ phone: 'md', desktop: 'xxl' }} align="center">
                        <div>
                            <H1>CONGRATULATIONS!</H1>
                            <P font="h3">Click below for other Financial Options:</P>
                            <Btn
                                tag="a"
                                size="xxl"
                                whiteSpace="wrap"
                                padding="sm"
                                href={matchLink}
                                rounded="xxl"
                                iconRight={<ArrowRightCircle size={desktop ? 120 : 64} />}
                            >
                                <span>
                                    <Type font={{ phone: 'h1', desktop: 'jumbo' }}>CONTINUE</Type>
                                    <br />
                                    <Type font="control">SPONSORED LISTING</Type>
                                </span>
                            </Btn>
                        </div>
                    </Box>
                </Grid>
            </Box>
            <Box>
                <Container size="lg" margin="auto" padding={['xxl', 'md']}>
                    <Grid columnGap="lg" columns={{ default: '1fr', laptop: '1fr 1fr 1fr' }} margin="auto">
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src="public/images/icons/icon-5.svg"
                                    alt="Complete our short online form, should take just a few minutes"
                                    style={{ maxWidth: '160px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H5 align="center">Complete our short online form, should take just a few minutes</H5>
                        </Box>
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src="public/images/icons/icon-6.svg"
                                    alt="Read over and sign the loan paperwork once approved"
                                    style={{ maxWidth: '160px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H5 align="center">Read over and sign the loan paperwork once approved</H5>
                        </Box>
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src="public/images/icons/icon-7.svg"
                                    alt="In as little as a day, the money is in your account!"
                                    style={{ maxWidth: '160px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H5 align="center">In as little as a day, the money is in your account!</H5>
                        </Box>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

Match.propTypes = {
    publicPath: PropTypes.string,
    imgPath: PropTypes.string,
    brandPath: PropTypes.string,
    matchLink: PropTypes.string,
};

Match.defaultProps = {
    publicPath: window.publicPath,
    imgPath: window.imgPath,
    brandPath: window.brandPath,
    matchLink: window.matchLink,
};

export default Match;
