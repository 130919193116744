import React from 'react';
import { Link } from 'react-router-dom';
import {
    Type,
    List,
} from '@saladbob/sassafras';

import ContentPage from '../components/ContentPage';

const About = () => (
    <ContentPage
        title="About Us"
        subtitle={`We are not affiliated with any government agencies. We are an independent company that has writers
            who have researched and investigated all aspects required to assist you in how to uncover any
            unclaimed assets and/or hidden funds. We took all this information compiled it into a FREE eBook
            for you to follow as a step-by-step guide.`}
    >
        <Type tag="h3" font="leader" mb="lg">
            Our overall goal is to help YOU discover any unclaimed assets, with this free informative eBook.
        </Type>
        <Type tag="p" mb="md">
            We created this eBook to help you get all the useful information you require to uncover if you have
            any hidden assets available. We are not providing legal, tax or financial advice. If you need such
            advice, please contact an attorney, accountant, or a financial advisor.
        </Type>
        <Type tag="h2" font="h2" mb="lg">
            Our Priority
        </Type>
        <Type tag="p" mb="md">
            If you have any questions or comments not related to any legal, tax and/or financial advice,
            please feel free to reach out to us through the
            {' '}
            <Link to="/contact">Contact Us</Link>
            .
        </Type>
        <Type tag="p" mb="md">
            So many people across the United States miss out on assets they’re entitled to, either because they
            weren’t aware that these ‘so-called’ assets existed or because they didn’t know where to start.
        </Type>
        <Type tag="p" mb="md">
            That’s why we decided to do a deep dive and learn the process and share with everyone.
        </Type>
        <Type tag="h3" font="h4" mb="md">
            Our Process:
        </Type>
        <List>
            <Type tag="li" mb="md">
                <Type tag="span" weight="bold">We Researched</Type>
                <br />
                Our team of writers spent a lot of time researching this particular program. We’ve also
                confirmed with each state and their practices regarding unclaimed assets.
            </Type>
            <Type tag="li" mb="md">
                <Type tag="span" weight="bold">We Made This Process Easy to Follow</Type>
                <br />
                Our writers did the research and some even uncovered hidden assets using the knowledge they
                uncovered. They then compiled all that research into a free informative eBook, that provides
                insight and helpful tips that are clear and easy to understand.
            </Type>
        </List>
    </ContentPage>
);

export default About;
