/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { createUseStyles } from 'react-jss';
import {
    ThemeContext,
    Box,
    PanelSteps,
    H3,
} from '@saladbob/sassafras';

import { useNavigate } from 'react-router-dom';

import FormStep from './FormStep';
import SearchLoader from './SearchLoader';
import Trivia from './trivia/Trivia';

const styles = createUseStyles({
    FormSteps: {
        zIndex: 2,
        transition: 'all ease 0.5s',
    },
    Body: {
        width: '100%',
        margin: 'auto',
        '& a': {
            fontWeight: 'bold',
            color: 'inherit',
            textDecoration: 'underline',
        },
    },
    '@media (max-width: 869px)': {
        Body: {
            flexWrap: 'wrap',
        },
    },
});

const Loading = ({ loading, onSubmit }) => (
    <Box
        maxWidth="640px"
        margin="auto"
        minHeight="calc(100vh - 100px)"
        padding={{ phone: 'md', laptop: ['lg', 0] }}
    >
        {!loading && (<H3 align="center" margin={['lg', 'auto']}>Please Wait While We Connect You With a Lender</H3>)}
        <Box
            ms="auto"
            me="auto"
            maxWidth="320px"
        >
            <SearchLoader size={60} color="primary" speed="4s" />
        </Box>
        <Trivia onSubmit={onSubmit} />
    </Box>
);

const FormSteps = ({
    fields,
    errMsg,
    userData,
    loading,
    onSubmit,
    prevStep,
    lastStep,
    onValidate,
    onError,
    onSkip,
    imagePath,
    step,
    path,
    publicPath,
    ...props
}) => {
    const theme = useContext(ThemeContext);
    const navigate = useNavigate();
    const [maxStep, setMaxStep] = useState(step);

    const classes = styles({ theme, ...props });

    const [disabled, setDisabled] = useState(false);

    const onQuestionSubmit = (questions, index) => {
        if (questions === index) {
            setTimeout(() => { navigate('/match'); }, 3000);
        }
    };

    const onExit = () => {
        const elem = document.querySelector(':focus');

        if (elem) {
            elem.blur();
        }

        setDisabled(true);
    };

    const onComplete = () => {
        if (step > maxStep) {
            setMaxStep(step);
        }
    };

    const onEntered = () => setDisabled(false);

    let steps = fields.map((formStep, index) => (
        <FormStep
            key={formStep.key}
            title={formStep.title}
            titleSize={formStep.titleSize}
            subtitle={formStep.subtitle}
            subtitleSize={formStep.subtitleSize}
            formTitle={formStep.formTitle}
            formTitleSize={formStep.formTitleSize}
            formSubtitle={formStep.formSubtitle}
            formSubtitleSize={formStep.formSubtitleSize}
            helpBox={formStep.helpBox}
            instructions={formStep.instructions}
            instructionsIcon={formStep.instructionsIcon}
            image={formStep.image}
            fields={formStep.fields}
            optin={formStep.optin}
            bottomText={formStep.bottomText}
            style={formStep.style}
            bgImage={formStep.bgImage}
            onSubmit={onSubmit}
            prevStep={prevStep}
            currentStep={index}
            lastStep={maxStep}
            disabled={disabled}
            userData={userData}
            stepCount={fields.length - 1}
        />
    ));

    steps = [...steps, (<Loading key="loading" loading={loading} onSubmit={onQuestionSubmit} />)];

    return (
        <Box
            className={classes.FormSteps}
            bgColor={theme.colors.form.bgColor}
            txtColor={theme.colors.form.txtColor}
        >
            <div className={classes.Body}>
                <PanelSteps
                    onComplete={onComplete}
                    step={step}
                    onExit={onExit}
                    onEntered={onEntered}
                    duration={1}
                    travel={100}
                >
                    {steps}
                </PanelSteps>
            </div>
        </Box>
    );
};

Loading.propTypes = {
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
};

FormSteps.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object),
    onSubmit: PropTypes.func,
    prevStep: PropTypes.func,
    lastStep: PropTypes.number,
    onValidate: PropTypes.func,
    onError: PropTypes.func,
    onSkip: PropTypes.func,
    imagePath: PropTypes.string,
    publicPath: PropTypes.string,
    step: PropTypes.number,
    path: PropTypes.string,
    loading: PropTypes.bool,
    userData: PropTypes.object,
    errMsg: PropTypes.string,
};

FormSteps.defaultProps = {
    step: 6,
};

export default FormSteps;
