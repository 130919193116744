/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    P,
    FieldComposite,
    Input,
} from '@saladbob/sassafras';

const onPaste = (e) => {
    e.preventDefault();
};

const SsnField = ({ name, font, ...props }) => (
    <FieldComposite name={name} {...props}>
        <Input
            name={`${name}1`}
            inputSize="3"
            placeholder="xxx"
            type="tel"
            autotab
            maxlength={3}
            width="auto"
            font={font}
            onPaste={onPaste}
            rules={{
                required: true, number: true, minlength: 3, maxlength: 3,
            }}
        />
        <P>-</P>
        <Input
            name={`${name}2`}
            inputSize="2"
            placeholder="xx"
            type="tel"
            autotab
            maxlength={2}
            width="auto"
            font={font}
            onPaste={onPaste}
            rules={{
                required: true, number: true, minlength: 2, maxlength: 2,
            }}
        />
        <P>-</P>
        <Input
            name={`${name}3`}
            inputSize="4"
            placeholder="xxxx"
            type="tel"
            maxlength={4}
            width="auto"
            font={font}
            onPaste={onPaste}
            rules={{
                required: true, number: true, minlength: 4, maxlength: 4,
            }}
        />
    </FieldComposite>
);

SsnField.propTypes = {
    name: PropTypes.string,
};

export default SsnField;
