import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '@saladbob/sassafras';
import { createUseStyles } from 'react-jss';
import styles from './LoadingClock.module.css';

const loadingClockStyles = createUseStyles({
    LoadingClock: {
        border: ({ theme, color }) => `8px solid ${theme.colors[color] || color}`,
        '&::after': {
            backgroundColor: ({ theme, color }) => theme.colors[color] || color,
        },
    },
});

const LoadingClock = ({ color }) => {
    const theme = useContext(ThemeContext);
    const classes = loadingClockStyles({ theme, color });

    return (
        <div className={`${styles.loadingClock} ${classes.LoadingClock}`}>
            <div className={styles.smHand} />
            <div className={styles.lgHand} />
        </div>
    );
};

LoadingClock.propTypes = {
    color: PropTypes.string,
};

export default LoadingClock;
