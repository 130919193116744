import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box, Grid, Type, ProgressBar,
} from '@saladbob/sassafras';

import LoadingList from '../components/LoadingList/LoadingList';

import freeLoader from '../utils/freeLoader';

const items = [
    'Creating Record',
    'Scanning Information',
    'Compiling Lender List',
    'Connecting With Lender',
    'Initiating Transaction',
];

const LoadingC = ({ time }) => {
    const [progress, setTime] = useState({});

    useEffect(() => {
        freeLoader(
            time,
            (pgs) => setTime(pgs),
            true,
            () => setTime(0),
        );
    }, []);

    return (
        <Box
            minHeight="calc(100vh - 88px)"
            bgColor="primary900"
            txtColor="white"
            verticalAlign="center"
            align="center"
        >
            <Type font="jumbo">
                Awaiting Confirmation
            </Type>
            <Type font="h4" mb="xl">
                Almost there! It may take up to 90 seconds to finalize your loan request
            </Type>
            <Grid columns={{ phone: '1fr', laptop: '1fr 1fr' }} gridGap="xl" mb="xl" maxWidth="840px">
                <Box mb="xl">
                    <Type tag="h2" font="loading" align="center" mb="lg">
                        {progress.hrPercent}
                        %
                    </Type>
                    <ProgressBar
                        progress={Number(progress.percent)}
                        bgColor="rgba(255, 255, 255, 0.3)"
                        progressColor="primary"
                        transition={false}
                        size="sm"
                    />
                </Box>
                <Box mb="xl">
                    <LoadingList percent={progress.percent} items={items} />
                </Box>
            </Grid>
            <Type font="p" tag="p" align="center">
                <strong>Do not cancel, reload, or leave this page until the process has been completed.</strong>
                <br />
                If you do not wait for this process to complete your loan may not be funded.
                Please be patient, as this may take a few moments.
            </Type>
        </Box>
    );
};

LoadingC.propTypes = {
    time: PropTypes.number,
};

LoadingC.defaultProps = {
    time: window.loadingTime || 120,
};

export default LoadingC;
