/* eslint-disable max-len */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const Badge = ({ color }) => {
    const style = useMemo(() => ({
        fill: color,
        strokeWidth: '0px',
    }), [color]);

    return (
        <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 638.43 638.43">
            <g id="_ò__1">
                <path
                    style={style}
                    d="m349.93,11.55l59.14,52.63c7.65,6.81,17.36,10.84,27.58,11.47l79.03,4.81c23.08,1.4,41.46,19.84,42.81,42.92l4.6,79.04c.6,10.22,4.61,19.94,11.39,27.61l52.48,59.28c15.33,17.31,15.29,43.35-.08,60.62l-52.63,59.14c-6.81,7.65-10.84,17.36-11.47,27.58l-4.81,79.03c-1.4,23.08-19.84,41.46-42.92,42.81l-79.04,4.6c-10.22.6-19.94,4.61-27.61,11.39l-59.28,52.48c-17.31,15.33-43.35,15.29-60.62-.08l-59.14-52.63c-7.65-6.81-17.36-10.84-27.58-11.47l-79.03-4.81c-23.08-1.4-41.46-19.84-42.81-42.92l-4.6-79.04c-.6-10.22-4.61-19.94-11.39-27.61l-52.48-59.28c-15.33-17.31-15.29-43.35.08-60.62l52.63-59.14c6.81-7.65,10.84-17.36,11.47-27.58l4.81-79.03c1.4-23.08,19.84-41.46,42.92-42.81l79.04-4.6c10.22-.6,19.94-4.61,27.61-11.39l59.28-52.48c17.31-15.33,43.35-15.29,60.62.08Z"
                />
            </g>
        </svg>
    );
};

Badge.propTypes = {
    color: PropTypes.string,
};

export default Badge;
