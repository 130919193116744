import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box, PanelSteps, H4,
} from '@saladbob/sassafras';

import Spinner from '../components/Spinner';
import Lock from '../components/svg/Lock';

const LoadingSecure = ({ time, redirect }) => {
    const [step, setStep] = useState(0);
    const redirectUser = () => {
        if (redirect) {
            window.open(redirect);
        }
        setStep(1);
    };

    useEffect(() => {
        setTimeout(redirectUser, time * 1000);
    }, []);

    return (
        <Box
            minHeight="calc(100vh - 88px)"
            verticalAlign="flex-start"
            align="center"
            pt="lg"
        >
            <PanelSteps step={step}>
                <div>
                    <H4 font="h4" mb="xl" weight="normal">
                        Checking if the site connection is secure.
                    </H4>
                    <Box mb="xl">
                        <Spinner color="primary" />
                    </Box>
                </div>
                <div>
                    <H4 font="h4" mb="md" weight="normal">
                        Connection is secure!
                    </H4>
                    <Box maxWidth="160px" margin="auto">
                        <Lock color="primary" />
                    </Box>
                </div>
            </PanelSteps>
        </Box>
    );
};

LoadingSecure.propTypes = {
    time: PropTypes.number,
    redirect: PropTypes.string,
};

LoadingSecure.defaultProps = {
    time: window.loadingTime || 5,
    redirect: window.loadingRedirect,
};

export default LoadingSecure;
