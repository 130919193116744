import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Btn } from '@saladbob/sassafras';

const styles = createUseStyles({
    Pagination: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        '& *': {
            margin: 8,
        },
        '& *:first-child': {
            marginLeft: 0,
        },
        '& *:last-child': {
            marginRight: 0,
        },
    },
});

const Pagination = ({
    page,
    total,
    change,
    activeColor,
    inactiveColor,
    activeTxtColor,
    inactiveTxtColor,
    rounded,
}) => {
    const classes = styles();

    const next = () => {
        if (typeof change === 'function') {
            change(Math.min(page + 1, total - 1));
        }
    };

    const prev = () => {
        if (typeof change === 'function') {
            change(Math.max(page - 1, 0));
        }
    };

    const goToPage = (p) => {
        if (typeof change === 'function') {
            change(p);
        }
    };

    const generateButtons = () => {
        const count = Math.min(10, total);
        let offset = 0;

        if (page >= 9) {
            offset = (Math.floor((page + 1) / 10) * 10) - 1;
        }

        const buttons = [];

        for (let i = 0; i < count; i += 1) {
            buttons.push((
                <Btn
                    key={`pagei-${i + offset}`}
                    color={i + offset === page ? activeColor : inactiveColor}
                    txtColor={i + offset === page ? activeTxtColor : inactiveTxtColor}
                    size="xs"
                    font="small"
                    onClick={() => goToPage(i + offset)}
                    rounded={rounded}
                >
                    {i + offset + 1}
                </Btn>
            ));
        }

        return buttons;
    };

    return (
        <div className={classes.Pagination}>
            <Btn
                size="xs"
                color="none"
                txtColor="neutral600"
                onClick={prev}
                rounded={rounded}
                font="small"
            >
                Back
            </Btn>
            {generateButtons()}
            <Btn
                color={inactiveColor}
                txtColor={inactiveTxtColor}
                size="xs"
                font="small"
                onClick={next}
                rounded={rounded}
            >
                Next
            </Btn>
        </div>
    );
};

Pagination.propTypes = {
    activeColor: PropTypes.string,
    inactiveColor: PropTypes.string,
    activeTxtColor: PropTypes.string,
    inactiveTxtColor: PropTypes.string,
    page: PropTypes.number,
    total: PropTypes.number,
    rounded: PropTypes.bool,
    change: PropTypes.func,
};

Pagination.defaultProps = {
    activeColor: 'primary',
    inactiveColor: 'none',
    activeTxtColor: 'white',
    inactiveTxtColor: 'neutral700',
};

export default Pagination;
