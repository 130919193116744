import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Type,
} from '@saladbob/sassafras';

const LinkWrapper = ({
    children, font, color, weight, underline, to,
}) => (
    <Type color={color} font={font} weight={weight} underline={underline}>
        <Link to={to}>
            {children}
        </Link>
    </Type>
);

LinkWrapper.propTypes = {
    children: PropTypes.node,
    font: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    color: PropTypes.string,
    underline: PropTypes.string,
    to: PropTypes.string,
    weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LinkWrapper.defaultProps = {
    weight: 'bold',
};

export default LinkWrapper;
